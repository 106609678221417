import React, { useEffect, useState } from "react";
import "./Document.css";
import { withUAL } from "ual-reactjs-renderer";
import { FaPlus } from "react-icons/fa";
import Header from "../header/Header";
import AddDocumentModal from "../modal/addDocumentModal/AddDocumentModal";
import { FaCopy } from "react-icons/fa";
import BlockchainService from "../../services/BlockchainService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader/Loader";
import DocumentFilters from "../searchFilters/documentFilters/DocumentFilters";
import { FaInfoCircle } from "react-icons/fa";

const blockchainService = new BlockchainService();

const Document = ({ ual }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [modalMode, setModalMode] = useState("add");
  const [documents, setDocuments] = useState([]);
  const [copiedHash, setCopiedHash] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [filtersActive, setFiltersActive] = useState(false);

  const copyToClipboard = (hash, event) => {
    event.stopPropagation(); // Stop event propagation to prevent opening modal
    navigator.clipboard.writeText(hash);
    setCopiedHash(hash);
    // Reset the tooltip text after some time
    setTimeout(() => setCopiedHash(""), 2000);
  };

  const formatHash = (hash) => {
    if (hash.length > 8) {
      return `${hash.substring(0, 4)}....${hash.substring(hash.length - 4)}`;
    }
    return hash;
  };

  const openModal = (document = null, index = null, mode = "add") => {
    setModalOpen(true);
    setCurrentDocument(document);
    setCurrentIndex(index);
    setModalMode(mode);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentDocument(null);
    setCurrentIndex(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://api.growingprosperity.farm/api/v1/user/log/document"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Document Data:", data);
        setIsNextPage(data.nextPage);
        setDocuments(data.data);
      } catch (error) {
        console.error("Error fetching document data:", error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    };

    fetchData();
  }, []); // This will cause the useEffect to run whenever documents change

  console.log("Document Data", documents);
  console.log("current doc", currentDocument);

  const handleDocumentSubmit = async (submittedDocumentData, index) => {
    setIsLoading(true);

    const formatDateWithSlashes = (dateStr) => {
      if (!dateStr) return "";
      const [year, month, day] = dateStr.split("-");
      return `${year}/${month}/${day}`; // Formats as 'YYYY/MM/DD'
    };

    let formattedSubmissionData = {
      ...submittedDocumentData,
      effectiveDate: formatDateWithSlashes(submittedDocumentData.effectiveDate),
      expirationDate: formatDateWithSlashes(
        submittedDocumentData.expirationDate
      ),
    };

    // Remove the ID from formattedSubmissionData if in "edit" mode
    if (modalMode === "edit") {
      const { id, userId, createdAt, updatedAt, ...rest } =
        formattedSubmissionData;
      formattedSubmissionData = rest;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, unable to submit document");
      setIsLoading(false);
      return;
    }

    const apiUrl =
      modalMode === "edit" && currentDocument?.id
        ? `https://api.growingprosperity.farm/api/v1/user/log/document/${currentDocument.id}`
        : "https://api.growingprosperity.farm/api/v1/user/log/document";

    const httpMethod = modalMode === "edit" ? "PUT" : "POST";

    try {
      const response = await fetch(apiUrl, {
        method: httpMethod,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify(formattedSubmissionData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.success) {
        const transactionData = responseData.tnx; // This is a placeholder and should match your actual API response

        // Submit the transaction to the blockchain
        const result = await blockchainService.pushTransaction(
          transactionData,
          ual,
          "invest", // The actual action name here depends on your smart contract
          {} // Any additional options required for the transaction
        );

        if (result.success) {
          toast.success(
            modalMode === "edit"
              ? "Document updated successfully"
              : "Document added successfully"
          );
        }

        // Update the documents array as needed
        if (modalMode === "edit") {
          setDocuments(
            documents.map((crop, idx) =>
              idx === index ? submittedDocumentData : documents
            )
          );
        } else {
          setDocuments([submittedDocumentData, ...documents]);
        }
      } else {
        toast.error(`Error: ${responseData.message}`);
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.error("Failed to submit document:", error);
      toast.error("Failed to submit document.");
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const paginate = async (pageNumber) => {
    setIsLoading(true); // Set loading to true when paginating
    try {
      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/document?page=${pageNumber}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Get response of documents", data);
      setIsNextPage(data.nextPage);
      setCurrentPage(pageNumber);
      setDocuments(data.data);
    } catch (error) {
      console.error("Error fetching crop data:", error);
    } finally {
      setIsLoading(false); // Set loading to false after paginating
    }
  };

  console.log(isNextPage);

  const deleteDocument = (documentIndex) => {
    const updatedDocuments = documents.filter(
      (_, index) => index !== documentIndex
    );
    setDocuments(updatedDocuments);
  };

  const handleSearch = async (filters) => {
    setIsLoading(true);
    try {
      const queryString = Object.entries(filters)
        .filter(([_, value]) => value !== "")
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/document?${queryString}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      if (data.data.length === 0) {
        toast.info("No results found for the applied filters.");

        setFiltersActive(true);
        setFilteredDocuments([]);
      } else {
        setFilteredDocuments(data.data);
        setFiltersActive(true);
      }
    } catch (error) {
      console.error("Error fetching filtered document data:", error);
      toast.error("Failed to fetch filtered data.");
      setFilteredDocuments([]); // Reset filtered documents on error
    } finally {
      setIsLoading(false);
    }
  };

  //  function to handle reset action
  const handleResetFilters = () => {
    setFilteredDocuments([]);
    setFiltersActive(false);
    console.log("Filtered Documents after reset:", filteredDocuments);
  };

  return (
    <div className="document">
      <Header ual={ual} />
      <div className="document-content">
        <div className="search-box">
          <div className="search-box-content">
            <DocumentFilters
              onSearch={handleSearch}
              onReset={handleResetFilters}
            />
          </div>
        </div>
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : filtersActive ? (
          filteredDocuments.length === 0 ? (
            <div className="no-data-message">No record found.</div>
          ) : (
            <div className="table-container">
              <table className="document-table">
                <thead>
                  <tr>
                    <th>
                      Record Type <FaInfoCircle />
                    </th>
                    <th>
                      Linked Record <FaInfoCircle />
                    </th>
                    <th>
                      Document Name <FaInfoCircle />
                    </th>
                    <th>
                      Document Type <FaInfoCircle />
                    </th>
                    <th>
                      Farm Name <FaInfoCircle />
                    </th>
                    <th>
                      Field Name <FaInfoCircle />
                    </th>
                    <th>
                      Effective Date <FaInfoCircle />
                    </th>
                    <th>
                      Expiration Date <FaInfoCircle />
                    </th>
                    <th>
                      Status <FaInfoCircle />
                    </th>
                    <th>
                      First Party <FaInfoCircle />
                    </th>
                    <th>
                      Second Party <FaInfoCircle />
                    </th>
                    <th>
                      Registrar <FaInfoCircle />
                    </th>
                    <th>
                      Registration No. <FaInfoCircle />
                    </th>
                    <th>
                      Registration Hash <FaInfoCircle />
                    </th>
                    <th>
                      Data Record <FaInfoCircle />
                    </th>
                    <th>
                      Expense Data <FaInfoCircle />
                    </th>
                    <th>
                      Memo <FaInfoCircle />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDocuments.map((document, index) => (
                    <tr
                      key={index}
                      onClick={() => openModal(document, index, "show")}>
                      <td>{document.recordType}</td>
                      <td>{document.linkedRecord}</td>
                      <td>{document.documentName}</td>
                      <td>{document.documentType}</td>
                      <td>{document.farmName}</td>
                      <td>{document.fieldName}</td>
                      <td>{document.effectiveDate}</td>
                      <td>{document.expirationDate}</td>
                      <td>{document.status}</td>
                      <td>{document.firstParty}</td>
                      <td>{document.secondParty}</td>
                      <td>{document.registrar}</td>
                      <td>{document.registrationNumber}</td>
                      <td>{document.registrationHash}</td>
                      <td>{document.dataRecord}</td>
                      <td>{document.expenseData}</td>
                      <td>{document.memo}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        ) : documents.length === 0 ? (
          <div className="no-data-message">No records found.</div>
        ) : (
          <div className="table-container">
            <table className="document-table">
              <thead>
                <tr>
                  <th>
                    Record Type <FaInfoCircle />
                  </th>
                  <th>
                    Linked Record <FaInfoCircle />
                  </th>
                  <th>
                    Document Name <FaInfoCircle />
                  </th>
                  <th>
                    Document Type <FaInfoCircle />
                  </th>
                  <th>
                    Farm Name <FaInfoCircle />
                  </th>
                  <th>
                    Field Name <FaInfoCircle />
                  </th>
                  <th>
                    Effective Date <FaInfoCircle />
                  </th>
                  <th>
                    Expiration Date <FaInfoCircle />
                  </th>
                  <th>
                    Status <FaInfoCircle />
                  </th>
                  <th>
                    First Party <FaInfoCircle />
                  </th>
                  <th>
                    Second Party <FaInfoCircle />
                  </th>
                  <th>
                    Registrar <FaInfoCircle />
                  </th>
                  <th>
                    Registration No. <FaInfoCircle />
                  </th>
                  <th>
                    Registration Hash <FaInfoCircle />
                  </th>
                  <th>
                    Data Record <FaInfoCircle />
                  </th>
                  <th>
                    Expense Data <FaInfoCircle />
                  </th>
                  <th>
                    Memo <FaInfoCircle />
                  </th>
                </tr>
              </thead>
              <tbody>
                {documents.map((document, index) => (
                  <tr
                    key={index}
                    onClick={() => openModal(document, index, "show")}>
                    <td>{document.recordType}</td>
                    <td>{document.linkedRecord}</td>
                    <td>{document.documentName}</td>
                    <td>{document.documentType}</td>
                    <td>{document.farmName}</td>
                    <td>{document.fieldName}</td>
                    <td>{document.effectiveDate}</td>
                    <td>{document.expirationDate}</td>
                    <td>{document.status}</td>
                    <td>{document.firstParty}</td>
                    <td>{document.secondParty}</td>
                    <td>{document.registrar}</td>
                    <td>{document.registrationNumber}</td>
                    <td>{document.registrationHash}</td>
                    <td>{document.dataRecord}</td>
                    <td>{document.expenseData}</td>
                    <td>{document.memo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="document-footer">
        <div className="pagination">
          <button
            className={`prev-btn${currentPage === 1 ? " disabled" : ""}`}
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}>
            Previous
          </button>
          <button
            className={`next-btn${!isNextPage ? " disabled" : ""}`}
            onClick={() => paginate(currentPage + 1)}
            disabled={!isNextPage}>
            Next
          </button>
        </div>
        {ual?.activeUser && (
          <button
            className="add-document-btn"
            onClick={() => openModal(null, null, "add")}>
            <FaPlus /> Add Document
          </button>
        )}
      </div>
      <AddDocumentModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleDocumentSubmit}
        document={currentDocument}
        mode={modalMode}
        setMode={setModalMode}
        currentIndex={currentIndex}
        onDelete={deleteDocument}
        ual={ual}
      />
    </div>
  );
};

export default withUAL(Document);
