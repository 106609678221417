import React, { useEffect, useState } from "react";
import { withUAL } from "ual-reactjs-renderer";
import "./Dashboard.css";
import Header from "../header/Header";
import LineChartGEA from "../charts/LineChartGEA";
import AreaChartGEA from "../charts/AreaChartGEA";
import BarChartStackedGEA from "../charts/BarChartStackedGEA";
import BarChartGEA from "../charts/BarChartGEA";
import ComposedChartGEA1 from "../charts/ComposedChartGEA1";
import ComposedChartGEA2 from "../charts/ComposedChartGEA2";
import PieChartGEA1 from "../charts/PieChartGEA1";
import PieChartGEA2 from "../charts/PieChartGEA2";
import RadarChartGEA from "../charts/RadarChartGEA";
import RadialChartGEA from "../charts/RadialChartGEA";
import Carousel from "./Carousel";
import ImageGallery from "./ImageGallery";
import GeaData from "./GeaData";

const Dashboard = ({ ual, isAuthenticated }) => {
  // console.log("Ual:", ual);

  return (
    <div className={`dashboard ${isAuthenticated ? "" : "dash_board"}`}>
      <Header ual={ual} />
      <div className="dashboard-content">
        <GeaData />
        <Carousel />
        {/* <h3 className="heading">Crop Harvest Peak Analysis</h3>
        <AreaChartGEA /> */}

        {/* <div className="Composed-charts">
          <div className="Composed-chart1">
            <h3 className="heading">Income chart</h3>
            <PieChartGEA1 />
          </div>
          <div className="Composed-chart2">
            <h3 className="heading">Expense Chart</h3>
            <PieChartGEA2 />
          </div>
        </div> */}

        {/* <h3 className="heading">Workforce Composition</h3>
        <LineChartGEA />

        <h3 className="heading">
          Agricultural Financial and Employment Overview
        </h3>
        <ComposedChartGEA1 /> */}

        {/* <div className="bar-charts1">
          <h3 className="heading">Document List</h3>
          <ol className="list">List name Items</ol>
          <ol className="list">List name Items</ol>
          <ol className="list">List name Items</ol>
          <ol className="list">List name Items</ol>
          <ol className="list">List name Items</ol>
          <ol className="list">List name Items</ol>
          <ol className="list">List name Items</ol>
        </div> */}

        <div className="bar-charts1">
          <h3 className="heading">Image Gallery</h3>
          <ImageGallery />
          {/* <BarChartGEA /> */}
        </div>
      </div>
    </div>
  );
};

export default withUAL(Dashboard);
