import React, { useEffect, useState } from "react";

const ExpenseFilters = ({ onSearch, onReset }) => {
  const initialState = {
    recordType: "",
    linkedRecord: "",
    period: "",
    type: "",
    class: "",
    recipient: "",
    ledger: "",
    recordReference: "",
    minAmount: "",
    maxAmount: "",
    currency: "",
    memo: "",
  };

  const [filters, setFilters] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOption, setFilterOption] = useState([]);

  useEffect(() => {
    const fetchCropFields = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://api.growingprosperity.farm/api/v1/expense/fields"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setFilterOption(data.data);
        console.log(data.data);
      } catch (error) {
        console.error("Could not fetch crop fields data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCropFields();
  }, []);

  const handleFocus = (event) => {
    event.target.type = "date";
  };

  const handleBlur = (event) => {
    if (event.target.value === "") {
      event.target.type = "text";
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [id]: value }));
  };

  const handleSearchClick = () => {
    onSearch(filters);
  };

  const handleResetClick = () => {
    setFilters(initialState);
    if (onReset) onReset();
  };

  const options = filterOption.length > 0 ? filterOption[0] : {};

  return (
    <div className="search-container">
      <div className="search-filters">
        <div className="upper-filter">
          <select
            id="recordType"
            className="filter-control"
            value={filters.recordType}
            onChange={handleChange}>
            <option value="">Record Type</option>
            <option value="new">New</option>
            <option value="old">Old</option>
            <option value="edited">Edited</option>
          </select>
          <input
            type="text"
            id="linkedRecord"
            className="filter-control"
            placeholder="Linked Record"
            value={filters.linkedRecord}
            onChange={handleChange}
          />
          <input
            type="text"
            id="period"
            className="filter-control"
            placeholder="Period"
            value={filters.period}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <select
            id="type"
            className="filter-control"
            value={filters.type}
            onChange={handleChange}>
            <option value="">Type</option>
            {options.type &&
              options.type.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </select>
          <select
            id="class"
            className="filter-control"
            value={filters.class}
            onChange={handleChange}>
            <option value="">Class</option>
            {options.class &&
              options.class.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </select>
          <select
            id="recipient"
            className="filter-control"
            value={filters.recipient}
            onChange={handleChange}>
            <option value="">Recipient</option>
            {options.recipient &&
              options.recipient.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </select>
          <button
            className="reset-btn"
            type="button"
            onClick={handleResetClick}>
            Reset
          </button>
        </div>
        <div className="lower-filter">
          <select
            id="ledger"
            className="filter-control"
            value={filters.ledger}
            onChange={handleChange}>
            <option value="">Ledger</option>
            {options.ledger &&
              options.ledger.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </select>
          <input
            type="text"
            id="recordReference"
            className="filter-control"
            placeholder="Record-Ref"
            value={filters.recordReference}
            onChange={handleChange}
          />
          <input
            type="number"
            id="minAmount"
            className="filter-control"
            placeholder="Min-Amount"
            value={filters.minAmount}
            onChange={handleChange}
          />
          <input
            type="number"
            id="maxAmount"
            className="filter-control"
            placeholder="Max-Amount"
            value={filters.maxAmount}
            onChange={handleChange}
          />

          <select
            id="currency"
            className="filter-control"
            value={filters.currency}
            onChange={handleChange}>
            <option value="">Currency</option>
            {options.currency &&
              options.currency.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </select>
          <input
            type="text"
            id="memo"
            className="filter-control"
            placeholder="Memo"
            value={filters.memo}
            onChange={handleChange}
          />

          <button
            className="search-btn"
            type="button"
            onClick={handleSearchClick}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExpenseFilters;
