import React from "react";
import "./LogoutModal.css";

const LogoutModal = ({ confirmLogout, cancelLogout }) => {
  return (
    <div
      className="logout-modal-overlay"
      style={{
        overflow: "auto",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}>
      <div
        className="logout-modal"
        style={{
          overflow: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}>
        <div
          className="modal-content"
          style={{
            overflow: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}>
          <p style={{ color: "red" }}>Are you sure you want to logout?</p>
          <div className="modal-buttons">
            <button className="button-yes" onClick={confirmLogout}>
              Yes
            </button>
            <button className="button-no" onClick={cancelLogout}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
