import React from "react";
import "./LogoutModal.css";

const LogoutModal = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="logout_modal-overlay">
      <div className="logout_modal">
        <h2>Are you sure you want to logout?</h2>
        <div className="logout_modal-buttons">
          <button className="confirm_button" onClick={onConfirm}>
            Yes
          </button>
          <button className="cancel_button" onClick={onCancel}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
