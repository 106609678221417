import React, { useState } from "react";
import "./EmploymentFilters.css";

const EmploymentFilters = ({ onSearch, onReset }) => {
  const initialState = {
    recordType: "",
    linkedRecord: "",
    period: "",
    totalEmploy: "",
    minMale: "",
    minFemale: "",
    minTotalEmployment: "",
    expanseData: "",
    memo: "",
  };

  const [filters, setFilters] = useState(initialState);

  const handleFocus = (event) => {
    event.target.type = "date";
  };

  const handleBlur = (event) => {
    if (event.target.value === "") {
      event.target.type = "text";
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [id]: value }));
  };

  const handleSearchClick = () => {
    onSearch(filters);
  };

  const handleResetClick = () => {
    setFilters(initialState);
    if (onReset) onReset();
  };

  return (
    <div className="search-container">
      <div className="search-filters">
        <div className="upper-filter">
          <select
            id="recordType"
            className="filter-control"
            value={filters.recordType}
            onChange={handleChange}>
            <option value="">Record Type</option>
            <option value="new">New</option>
            <option value="old">Old</option>
            <option value="edited">Edited</option>
          </select>
          <input
            type="text"
            id="linkedRecord"
            className="filter-control"
            placeholder="Linked Record"
            value={filters.linkedRecord}
            onChange={handleChange}
          />
          <input
            type="text"
            id="period"
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="filter-control"
            placeholder="Period"
            value={filters.period}
            onChange={handleChange}
          />
          <input
            type="number"
            id="totalEmploy"
            className="filter-control"
            placeholder="Total Employ"
            value={filters.totalEmploy}
            onChange={handleChange}
          />
          <input
            type="number"
            id="filler"
            className="filter-control"
            placeholder="Filler"
            value={filters.filler}
            onChange={handleChange}
          />
          <button
            className="reset-btn"
            type="button"
            onClick={handleResetClick}>
            Reset
          </button>
        </div>
        <div className="lower-filter">
          <input
            type="number"
            id="minMale"
            className="filter-control"
            placeholder="Min-Male"
            value={filters.minMale}
            onChange={handleChange}
          />
          <input
            type="number"
            id="minFemale"
            className="filter-control"
            placeholder="Min-Female"
            value={filters.minFemale}
            onChange={handleChange}
          />
          <input
            type="number"
            id="minTotalEmployment"
            className="filter-control"
            placeholder="Min-Total Employment"
            value={filters.minTotalEmployment}
            onChange={handleChange}
          />
          <input
            type="number"
            id="expanseData"
            className="filter-control"
            placeholder="Expense Data"
            value={filters.expanseData}
            onChange={handleChange}
          />
          <input
            type="text"
            id="memo"
            className="filter-control"
            placeholder="Memo"
            value={filters.memo}
            onChange={handleChange}
          />
          <button
            className="search-btn"
            type="button"
            onClick={handleSearchClick}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmploymentFilters;
