import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import "./AddIncomeModal.css";
import crop4 from "../../../images/crop4.jpeg";
import crop2new from "../../../images/crop2new.jpg";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";

const AddIncomeModal = ({
  isOpen,
  onClose,
  onSubmit,
  income,
  mode,
  setMode,
  currentIndex,
  onDelete,
  ual,
}) => {
  // Default structure for an income record
  const defaultIncomeData = {
    recordType: "",
    linkedRecord: "",
    period: "",
    type: "",
    source: "",
    ledger: "",
    recordReference: "",
    amount: "",
    currency: "",
    memo: "",
  };

  console.log("Income in modal:", income);

  // State for the current mode of the modal and the editable data
  const [currentMode, setCurrentMode] = useState(mode);
  const [editableIncomeData, setEditableIncomeData] = useState({
    ...defaultIncomeData,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectData, setSelectData] = useState([]);

  useEffect(() => {
    const fetchCropFields = async () => {
      if (isOpen) {
        setIsLoading(true);
        try {
          const response = await fetch(
            "https://api.growingprosperity.farm/api/v1/income/fields"
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setSelectData(data.data);
          console.log(data.data);
        } catch (error) {
          console.error("Could not fetch crop fields data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchCropFields();
  }, [isOpen]);

  console.log("Select Data", selectData);

  useEffect(() => {
    if (["edit", "show"].includes(mode) && income) {
      setEditableIncomeData(income);
    } else if (mode === "add") {
      setEditableIncomeData(defaultIncomeData);
    }
    setCurrentMode(mode);
  }, [income, mode]);

  // Function to switch to edit mode
  const handleEdit = () => {
    setMode("edit"); // Updates the mode to 'edit'
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Authentication required.");
      setIsLoading(false);
      return;
    }

    try {
      // Adjust the URL to your actual API endpoint for deleting an income record
      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/income/${income.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete income record.");
      }

      // Call the onDelete function passed as a prop to update the parent component's state
      onDelete(currentIndex);
      toast.success("Income record successfully deleted.");
    } catch (error) {
      toast.error(
        `An error occurred while deleting the income record: ${error.message}`
      );
      console.error(error);
    } finally {
      setIsLoading(false);
      onClose(); // Close the modal after operation
    }
  };

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableIncomeData((prevData) => ({ ...prevData, [name]: value }));
  };

  const splitNameOnUppercase = (name) => {
    const index = Array.from(name).findIndex(
      (char) => char === char.toUpperCase()
    );
    console.log("INDEX", index);
    if (index !== -1) {
      const part1 = name.charAt(0).toUpperCase() + name.slice(1, index) + " ";

      const part2 = name.slice(index);
      console.log("part name", `${part1} ${part2}`);
      return `${part1} ${part2}`;
    } else {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const submissionData = { ...editableIncomeData };

    // If 'recordType' is 'New', ensure 'linkedRecord' is an empty string
    if (submissionData.recordType === "New") {
      submissionData.linkedRecord = " ";
    }

    if (["edit", "add"].includes(currentMode)) {
      onSubmit(submissionData, currentIndex);
    }
    onClose();
  };

  if (!isOpen) return null;

  // Function to render form fields dynamically based on the income data structure
  const renderFormContent = () => {
    const excludeFieldsInShowMode = ["id", "userId"];
    const excludeFieldsInEditMode = ["id", "userId", "createdAt", "updatedAt"];

    // Use dynamicOptions based on the data suitable for your income fields
    const dynamicOptions = selectData.length > 0 ? selectData[0] : {};

    return Object.keys(editableIncomeData).map((key) => {
      if (currentMode === "show" && excludeFieldsInShowMode.includes(key)) {
        return null;
      }

      if (currentMode === "edit" && excludeFieldsInEditMode.includes(key)) {
        return null;
      }

      // Exclude "linkedRecord" if "recordType" is "New"
      if (
        key === "linkedRecord" &&
        editableIncomeData["recordType"] === "New"
      ) {
        return null; // Skip rendering "linkedRecord" when "New" is selected for "recordType"
      }

      // In "show" mode, display values as text instead of input fields
      if (currentMode === "show") {
        return (
          <div className="input-box" key={key}>
            <label className="form-label">{splitNameOnUppercase(key)}:</label>
            <p className="form-text">{editableIncomeData[key]}</p>
          </div>
        );
      }

      // Specific input handling for "period" as a date
      if (key === "period") {
        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <input
              type="date"
              id={key}
              name={key}
              className="input form-input"
              value={editableIncomeData[key] || ""}
              onChange={handleChange}
              required={currentMode !== "show"}
            />
          </div>
        );
      }

      // "recordType" field with dynamic or predefined options in "add" mode
      if (key === "recordType" && currentMode === "add") {
        const recordTypeOptions = dynamicOptions["recordType"] || [
          "New",
          "Edit",
          "Update",
        ];

        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <select
              id={key}
              name={key}
              className="select form-select"
              value={editableIncomeData[key] || ""}
              onChange={handleChange}
              required>
              <option value="">Select an option</option>
              {recordTypeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      } else if (currentMode === "add" && dynamicOptions[key]) {
        // Handling other fields with dynamic options in "add" mode
        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <select
              id={key}
              name={key}
              className="select form-select"
              value={editableIncomeData[key] || ""}
              onChange={handleChange}
              required>
              <option value="">Select an option</option>
              {dynamicOptions[key].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      } else {
        // Default input handling for text and other types
        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <input
              type="text"
              id={key}
              name={key}
              className="input form-input"
              value={editableIncomeData[key] || ""}
              onChange={handleChange}
              required={currentMode !== "show"}
            />
          </div>
        );
      }
    });
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <div className="modal-image">
          <img className="crop-img" src={crop4} alt="" />
          <div class="overlay">
            <img src={crop2new} alt="" className="overlay-image" />
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-heading">
              {currentMode === "edit"
                ? "Edit Income"
                : currentMode === "add"
                ? "Add Income"
                : "Income Details"}
            </h2>
            <FaTimes className="modal-close" onClick={onClose} />
          </div>
          <form onSubmit={handleSubmit} className="modal-form">
            {renderFormContent()}
            {currentMode === "show" && ual?.activeUser && (
              <div className="show-btns">
                <button
                  type="button"
                  className="button-common edit-btn"
                  onClick={handleEdit}>
                  Edit
                </button>
                <button
                  type="button"
                  className="button-common delete-btn"
                  onClick={handleDelete}>
                  Delete
                </button>
              </div>
            )}
            {currentMode !== "show" && (
              <button type="submit" className="button-common submit-btn">
                {currentMode === "add" ? "Add" : "Update"}
              </button>
            )}
            {isLoading && <Loader isLoading={isLoading} />}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddIncomeModal;
