import React, { useState, useEffect } from "react";
import crypto from "crypto-js";
import "./Header.css";
import { FaSignInAlt, FaSignOutAlt } from "react-icons/fa";
import LogoutModal from "../modal/logoutModal/LogoutModal";
import { Link } from "react-router-dom";

const Header = ({ ual }) => {
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoginSuccess(true);
    } else if (ual?.activeUser) {
      sendWalletNameAndHash();
    }
  }, [ual?.activeUser]);

  const handleLogin = async () => {
    if (ual?.showModal) {
      await ual.showModal();
    } else {
      console.error(
        "UAL showModal method is not available. Make sure UAL is initialized correctly."
      );
    }
  };

  const sendWalletNameAndHash = async () => {
    if (!ual?.activeUser) {
      console.error(
        "UAL active user is undefined. Ensure the UAL provider is correctly set."
      );
      return;
    }

    const accountName = ual.activeUser.accountName;
    const key = process.env.REACT_APP_SECRET_KEY;
    const hash = crypto.SHA256(accountName, key).toString(crypto.enc.Hex);
    const signatureData = accountName + hash;
    const signature = crypto
      .SHA256(signatureData, key)
      .toString(crypto.enc.Hex);

    const payload = {
      accountName: accountName,
      hash: hash,
      signature: signature,
    };

    try {
      const response = await fetch(
        "https://api.growingprosperity.farm/api/v1/user/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("API Response:", responseData);
      if (responseData?.token) {
        localStorage.setItem("token", responseData.token);
        setLoginSuccess(true);
      }
    } catch (error) {
      console.error("Error sending data to API:", error);
    }
  };

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    ual.logout();
    localStorage.removeItem("token");
    setLoginSuccess(false);
    setShowLogoutModal(false);
  };

  const cancelLogout = () => {
    setShowLogoutModal(false);
  };

  return (
    <div className="header">
      <div className="header-content">
        <Link
          style={{ textDecoration: "none" }}
          target="_blank"
          to="https://growingprosperity.io/">
          <button style={{ marginRight: "15px" }} className="button login-btn">
            Go To Website
          </button>
        </Link>
        {ual?.activeUser && loginSuccess && (
          <>
            <span className="user-name">{ual.activeUser.accountName}</span>
            <button className="button logout-btn" onClick={handleLogout}>
              <FaSignOutAlt />
              A. Logout
            </button>
          </>
        )}
      </div>
      {!loginSuccess && (
        <div className="header-content-two">
          <button className="button login-btn" onClick={handleLogin}>
            <FaSignInAlt />
            A. Login
          </button>
        </div>
      )}
      {showLogoutModal && (
        <LogoutModal
          confirmLogout={confirmLogout}
          cancelLogout={cancelLogout}
        />
      )}
    </div>
  );
};

export default Header;

// --------------------------------------------- new code --------------------------------------------------------------------
