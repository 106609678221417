import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import "./AddFarmModal.css";
import crop4 from "../../../images/crop4.jpeg";
import crop2new from "../../../images/crop2new.jpg";
import { toast } from "react-toastify";

const AddFarmModal = ({
  isOpen,
  onClose,
  onSubmit,
  farm,
  mode,
  setMode,
  currentIndex,
  onDelete,
}) => {
  const defaultFarmData = {
    record_type: "new",
    linked_record: "",
    farm_name: "",
    field_name: JSON.stringify({ field: [] }), // Prepare for adding fields dynamically
    land_owner: "",
    memo: "",
  };

  const [currentMode, setCurrentMode] = useState(mode);
  const [editableFarmData, setEditableFarmData] = useState(defaultFarmData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (["edit", "show"].includes(mode) && farm) {
      const farmData = { ...farm };
      if (typeof farmData.field_name === "string") {
        try {
          farmData.field_name = JSON.parse(farmData.field_name);
        } catch (error) {
          console.error("Error parsing field_name:", error);
          farmData.field_name = JSON.stringify({ field: [] }); // Fallback to an empty structure on parse failure
        }
      }
      setEditableFarmData(farmData);
    } else if (mode === "add") {
      setEditableFarmData(defaultFarmData);
    }
    setCurrentMode(mode);
  }, [farm, mode]);

  const handleEdit = () => setMode("edit");

  const handleDelete = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token"); // Retrieve the token from local storage
    if (!token) {
      console.error("No token found, unable to submit document");
      setIsLoading(false);
      return;
    }

    try {
      const id = farm.id; // Assuming 'farm' is available in the scope

      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/farm/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.success) {
        setIsLoading(false);
        onClose();
        toast.success("Data deleted successfully");
        onDelete(currentIndex);
      } else {
        toast.error("Failed to delete data");
      }
      console.log("API Response:", responseData);
    } catch (error) {
      console.error("Failed to submit document:", error);
      toast.error(error.message || "An error occurred");
    }
  };

  const handleChange = (e, fieldName) => {
    const { name, value } = e.target;
    if (name === "field_name") {
      let fields;
      try {
        fields = JSON.parse(editableFarmData.field_name);
      } catch (error) {
        fields = { field: [] };
      }
      fields[fieldName] = value;
      setEditableFarmData((prevData) => ({
        ...prevData,
        field_name: JSON.stringify(fields),
      }));
    } else {
      setEditableFarmData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const splitNameOnUppercase = (name) => {
    const index = Array.from(name).findIndex(
      (char) => char === char.toUpperCase()
    );
    console.log("INDEX", index);
    if (index !== -1) {
      const part1 = name.charAt(0).toUpperCase() + name.slice(1, index) + " ";

      const part2 = name.slice(index);
      console.log("part name", `${part1} ${part2}`);
      return `${part1} ${part2}`;
    } else {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  };

  const handleAddField = () => {};

  const renderFieldNameInputs = () => {
    let fields;
    try {
      fields = JSON.parse(editableFarmData.field_name).field;
    } catch (error) {
      fields = [];
    }

    // For "show" mode, return the fields as a comma-separated list
    if (currentMode === "show") {
      return <p>{fields.join(", ")}</p>;
    }

    // For "edit" mode, return input elements for each field
    return fields.map((value, index) => (
      <div className="input-box" key={index}>
        <label htmlFor={`field-${index}`}>{`Field ${index + 1}`}</label>
        <input
          type="text"
          id={`field-${index}`}
          name={`field-${index}`}
          value={value}
          onChange={(e) => handleChange(e, `field[${index}]`)}
          required
        />
        {currentMode === "edit" && (
          <button type="button" onClick={() => handleAddField(index)}>
            +
          </button>
        )}
      </div>
    ));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(editableFarmData, currentIndex);
    onClose();
  };

  if (!isOpen) return null;

  const formatFieldName = (name) => {
    // Split the string on underscores and capitalize the first letter of each word
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const renderFormContent = () => {
    return Object.keys(editableFarmData).map((key) => {
      // Exclude fields in "edit" mode
      if (
        currentMode === "edit" &&
        ["id", "userId", "createdAt", "updatedAt"].includes(key)
      ) {
        return null;
      }
      // Exclude specific fields in "show" mode
      if (
        currentMode === "show" &&
        ["id", "userId", "field_name"].includes(key)
      ) {
        return null;
      }

      if (key === "field_name" && currentMode === "show") {
        // Render fields inside field_name when in "show" mode
        return renderFieldNameInputs();
      }

      // Apply formatting only in "add" mode
      const fieldName = currentMode === "add" ? formatFieldName(key) : key;

      const formattedKey = formatFieldName(key);

      return (
        <div className="input-box" key={key}>
          {key !== "field_name" && key !== "id" && key !== "userId" && (
            <label htmlFor={key}>{formattedKey}</label>
          )}
          {currentMode !== "show" ? (
            <input
              type="text"
              id={key}
              name={key}
              value={editableFarmData[key]}
              onChange={handleChange}
              required={currentMode !== "show"}
            />
          ) : (
            <p style={{ marginTop: "0" }}>{editableFarmData[key]}</p>
          )}
        </div>
      );
    });
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <div className="modal-image">
          <img className="crop-img" src={crop4} alt="" />
          <div class="overlay">
            <img src={crop2new} alt="" className="overlay-image" />
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-heading">
              {currentMode === "edit"
                ? "Edit Farm"
                : currentMode === "add"
                ? "Add Farm"
                : "Farm Details"}
            </h2>
            <FaTimes className="modal-close" onClick={onClose} />
          </div>
          <form onSubmit={handleSubmit} className="modal-form">
            {renderFormContent()}
            {currentMode === "show" && (
              <div className="show-btns">
                <button
                  type="button"
                  className="button-common edit-btn"
                  onClick={handleEdit}>
                  Edit
                </button>
                <button
                  type="button"
                  className="button-common delete-btn"
                  onClick={handleDelete}>
                  Delete
                </button>
              </div>
            )}
            {currentMode !== "show" && (
              <button type="submit" className="button-common submit-btn">
                {currentMode === "add" ? "Add" : "Update"}
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddFarmModal;

// import React, { useEffect, useState } from "react";
// import { FaTimes } from "react-icons/fa";
// import "./AddFarmModal.css";
// import crop4 from "../../../images/crop4.jpeg";
// import crop2new from "../../../images/crop2new.jpg";
// import { toast } from "react-toastify";
// import Loader from "../../loader/Loader"; // Ensure this path is correct

// const AddFarmModal = ({
//   isOpen,
//   onClose,
//   onSubmit,
//   farm,
//   mode,
//   setMode,
//   currentIndex,
//   onDelete,
//   ual, // If you are not using `ual`, remove this prop
// }) => {
//   const defaultFarmData = {
//     recordType: "new",
//     linkedRecord: "",
//     farmName: "",
//     fieldName: JSON.stringify({ field: [] }),
//     landOwner: "",
//     memo: "",
//   };

//   const [currentMode, setCurrentMode] = useState(mode);
//   const [editableFarmData, setEditableFarmData] = useState(defaultFarmData);
//   const [isLoading, setIsLoading] = useState(false);
//   const [selectData, setSelectData] = useState([]);

//   console.log("Farm in Add farm modal", farm);

//   useEffect(() => {
//     const fetchFarmFields = async () => {
//       if (isOpen) {
//         setIsLoading(true);
//         try {
//           const response = await fetch(
//             "https://api.growingprosperity.farmapi/v1/farm/fields"
//           );
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }
//           const data = await response.json();
//           setSelectData(data.data);
//           console.log(data.data);
//         } catch (error) {
//           console.error("Could not fetch farm fields data:", error);
//         } finally {
//           setIsLoading(false);
//         }
//       }
//     };
//     fetchFarmFields();
//   }, [isOpen]);

//   useEffect(() => {
//     if (["edit", "show"].includes(mode) && farm) {
//       setEditableFarmData(farm);
//     } else if (mode === "add") {
//       setEditableFarmData(defaultFarmData);
//     }
//     setCurrentMode(mode);
//   }, [farm, mode]);

//   const handleEdit = () => {
//     setMode("edit");
//   };

//   const handleDelete = async () => {
//     setIsLoading(true);
//     const token = localStorage.getItem("token");
//     if (!token) {
//       console.error("No token found, unable to submit document");
//       setIsLoading(false);
//       return;
//     }

//     try {
//       const id = farm.id;
//       const response = await fetch(
//         `https://api.growingprosperity.farmapi/v1/user/log/farm/${id}`,
//         {
//           method: "DELETE",
//           headers: {
//             "Content-Type": "application/json",
//             "x-auth-token": token,
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const responseData = await response.json();
//       if (responseData.success) {
//         setIsLoading(false);
//         onClose();
//         toast.success("Farm deleted successfully");
//         onDelete(currentIndex);
//       } else {
//         toast.error("Failed to delete the farm");
//       }
//     } catch (error) {
//       console.error("Failed to delete the farm:", error);
//       toast.error(error.message || "An error occurred");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setEditableFarmData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onSubmit(editableFarmData, currentIndex);
//     onClose();
//   };

//   const renderFormContent = () => {
//     // Logic to render form content based on currentMode
//     // This needs to be implemented based on your form structure
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="modal-backdrop">
//       <div className="modal-container">
//         <div className="modal-image">
//           <img className="farm-img" src={crop4} alt="" />
//           <div className="overlay">
//             <img src={crop2new} alt="" className="overlay-image" />
//           </div>
//         </div>
//         <div className="modal-content">
//           <div className="modal-header">
//             <h2 className="modal-heading">
//               {currentMode === "edit"
//                 ? "Edit Farm"
//                 : currentMode === "add"
//                 ? "Add Farm"
//                 : "Farm Details"}
//             </h2>
//             <FaTimes className="modal-close" onClick={onClose} />
//           </div>
//           <form onSubmit={handleSubmit} className="modal-form">
//             {renderFormContent()}
//             {currentMode === "show" && (
//               <div className="show-btns">
//                 <button
//                   type="button"
//                   className="button-common edit-btn"
//                   onClick={handleEdit}>
//                   Edit
//                 </button>
//                 <button
//                   type="button"
//                   className="button-common delete-btn"
//                   onClick={handleDelete}>
//                   Delete
//                 </button>
//               </div>
//             )}
//             {currentMode !== "show" && (
//               <button type="submit" className="button-common submit-btn">
//                 {currentMode === "add" ? "Add" : "Update"}
//               </button>
//             )}
//             {isLoading && <Loader isLoading={isLoading} />}
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddFarmModal;
