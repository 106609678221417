import React, { useState } from "react";

const FarmFilters = ({ onSearch, onReset }) => {
  const initialState = {
    recordType: "",
    linkedRecord: "",
    farmName: "",
    fieldName: "",
    field: "",
    landOwner: "",
    memo: "",
  };

  const [filters, setFilters] = useState(initialState);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [id]: value }));
  };

  const handleSearchClick = () => {
    onSearch(filters);
  };

  const handleResetClick = () => {
    setFilters(initialState); // Reset filter state to initial
    if (onReset) onReset(); // Optionally call onReset to clear filtered data in parent component
  };

  return (
    <div className="search-container">
      <div className="search-filters">
        <div className="upper-filter">
          <select
            id="recordType"
            className="filter-control"
            value={filters.recordType}
            onChange={handleChange}>
            <option value="">Record Type</option>
            <option value="new">New</option>
            <option value="old">Old</option>
            <option value="edited">Edited</option>
          </select>
          <input
            type="text"
            id="linkedRecord"
            className="filter-control"
            value={filters.linkedRecord}
            placeholder="Linked Record"
            onChange={handleChange}
          />
          <input
            type="text"
            id="farmName"
            className="filter-control"
            value={filters.farmName}
            placeholder="Farm Name"
            onChange={handleChange}
          />
          <input
            type="text"
            id="fieldName"
            className="filter-control"
            value={filters.fieldName}
            placeholder="Field Name"
            onChange={handleChange}
          />
          <button
            className="reset-btn"
            type="button"
            onClick={handleResetClick}>
            Reset
          </button>
        </div>
        <div className="lower-filter">
          <input
            type="text"
            id="field"
            className="filter-control"
            value={filters.field}
            placeholder="Field"
            onChange={handleChange}
          />
          <input
            type="text"
            id="landOwner"
            className="filter-control"
            value={filters.landOwner}
            placeholder="Land Owner"
            onChange={handleChange}
          />
          <input
            type="text"
            id="memo"
            className="filter-control"
            value={filters.memo}
            placeholder="Memo"
            onChange={handleChange}
          />

          <input
            type="text"
            id="filler"
            className="filter-control"
            value={filters.filler}
            placeholder="Filler"
            onChange={handleChange}
          />
          <button
            className="search-btn"
            type="button"
            onClick={handleSearchClick}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default FarmFilters;
