import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import logo_main from "../../images/logo-main.avif";
import {
  FaTachometerAlt,
  FaPeopleCarry,
  FaSignOutAlt,
  FaSignInAlt,
  FaCog,
  FaQuestionCircle,
  FaTimes,
  FaBars,
} from "react-icons/fa";
import { GiPlantsAndAnimals, GiFarmTractor } from "react-icons/gi";
import { IoDocumentText } from "react-icons/io5";
import { SiExpensify } from "react-icons/si";
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import LogoutModal from "../../pages/login/LogoutModal"; // Import the modal component

const Sidebar = ({
  isAuthenticated,
  onLogout,
  isSidebarVisible,
  toggleSidebar,
}) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };

  const handleLogoutConfirm = () => {
    setIsLogoutModalOpen(false);
    onLogout(); // Call the onLogout function passed as a prop
  };

  const handleLogoutCancel = () => {
    setIsLogoutModalOpen(false);
  };

  return (
    <div className={`sidebar ${isSidebarVisible ? "" : "collapsed"}`}>
      <div className="sidebar-header">
        <div className="logo-section" onClick={toggleSidebar}>
          {isSidebarVisible && (
            <img src={logo_main} alt="Logo" className="logo-icon" />
          )}
          <button className="toggle-button" onClick={toggleSidebar}>
            {isSidebarVisible ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
      <div className="sidebar-menu">
        <div className="menu-section">
          {isSidebarVisible && <h2>General</h2>}
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive ? "active menu-item" : "menu-item"
            }
            data-tooltip="Dashboard">
            <FaTachometerAlt className="icon" />
            {isSidebarVisible && <span>Dashboard</span>}
          </NavLink>
          <NavLink
            to="/farm"
            className={({ isActive }) =>
              isActive ? "active menu-item" : "menu-item"
            }
            data-tooltip="Farm">
            <GiFarmTractor className="icon" />
            {isSidebarVisible && <span>Farm</span>}
          </NavLink>
          <NavLink
            to="/crop"
            className={({ isActive }) =>
              isActive ? "active menu-item" : "menu-item"
            }
            data-tooltip="Crop">
            <GiPlantsAndAnimals className="icon" />
            {isSidebarVisible && <span>Crop</span>}
          </NavLink>
          <NavLink
            to="/employment"
            className={({ isActive }) =>
              isActive ? "active menu-item" : "menu-item"
            }
            data-tooltip="Employment">
            <FaPeopleCarry className="icon" />
            {isSidebarVisible && <span>Employment</span>}
          </NavLink>
          <NavLink
            to="/expense"
            className={({ isActive }) =>
              isActive ? "active menu-item" : "menu-item"
            }
            data-tooltip="Expense">
            <SiExpensify className="icon" />
            {isSidebarVisible && <span>Expense</span>}
          </NavLink>
          <NavLink
            to="/income"
            className={({ isActive }) =>
              isActive ? "active menu-item" : "menu-item"
            }
            data-tooltip="Income">
            <RiMoneyDollarBoxFill className="icon" />
            {isSidebarVisible && <span>Income</span>}
          </NavLink>
          <NavLink
            to="/document"
            className={({ isActive }) =>
              isActive ? "active menu-item" : "menu-item"
            }
            data-tooltip="Document">
            <IoDocumentText className="icon" />
            {isSidebarVisible && <span>Document</span>}
          </NavLink>
        </div>
        {isAuthenticated && (
          <div className="menu-section">
            {isSidebarVisible && <h2>Management</h2>}
            <NavLink
              to="/manage-farm"
              className={({ isActive }) =>
                isActive ? "active menu-item" : "menu-item"
              }
              data-tooltip="Manage Farm">
              <GiFarmTractor className="icon" />
              {isSidebarVisible && <span>Farm</span>}
            </NavLink>
            <NavLink
              to="/manage-crop"
              className={({ isActive }) =>
                isActive ? "active menu-item" : "menu-item"
              }
              data-tooltip="Manage Crop">
              <GiPlantsAndAnimals className="icon" />
              {isSidebarVisible && <span>Crop</span>}
            </NavLink>
            <NavLink
              to="/manage-expense"
              className={({ isActive }) =>
                isActive ? "active menu-item" : "menu-item"
              }
              data-tooltip="Manage Expense">
              <SiExpensify className="icon" />
              {isSidebarVisible && <span>Expense</span>}
            </NavLink>
            <NavLink
              to="/manage-income"
              className={({ isActive }) =>
                isActive ? "active menu-item" : "menu-item"
              }
              data-tooltip="Manage Income">
              <RiMoneyDollarBoxFill className="icon" />
              {isSidebarVisible && <span>Income</span>}
            </NavLink>
            <NavLink
              to="/manage-document"
              className={({ isActive }) =>
                isActive ? "active menu-item" : "menu-item"
              }
              data-tooltip="Manage Document">
              <IoDocumentText className="icon" />
              {isSidebarVisible && <span>Document</span>}
            </NavLink>
          </div>
        )}
        <div className="menu-section">
          {isSidebarVisible && <h2>Support</h2>}
          <NavLink
            to="/setting"
            className={({ isActive }) =>
              isActive ? "active menu-item" : "menu-item"
            }
            data-tooltip="Setting">
            <FaCog className="icon" />
            {isSidebarVisible && <span>Setting</span>}
          </NavLink>
          <NavLink
            to="/help"
            className={({ isActive }) =>
              isActive ? "active menu-item" : "menu-item"
            }
            data-tooltip="Help">
            <FaQuestionCircle className="icon" />
            {isSidebarVisible && <span>Help</span>}
          </NavLink>
        </div>
        {isAuthenticated && (
          <div className="menu-section">
            <button
              className="menu-item logout-button"
              onClick={handleLogoutClick}
              data-tooltip="Logout">
              <FaSignOutAlt className="icon" />
              {isSidebarVisible && <span>Logout</span>}
            </button>
          </div>
        )}
        {!isAuthenticated && (
          <NavLink
            to="/login"
            className={({ isActive }) =>
              isActive ? "active menu-item" : "menu-item"
            }
            data-tooltip="Login">
            <FaSignInAlt className="icon" />
            {isSidebarVisible && <span>Login</span>}
          </NavLink>
        )}
      </div>
      <LogoutModal
        isOpen={isLogoutModalOpen}
        onConfirm={handleLogoutConfirm}
        onCancel={handleLogoutCancel}
      />
    </div>
  );
};

export default Sidebar;
