import React, { useEffect, useState } from "react";
import { FaTimes, FaPlus, FaTrash } from "react-icons/fa";
import "./AddCropModal.css";
import crop4 from "../../../images/crop4.jpeg";
import crop2new from "../../../images/crop2new.jpg";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";

const schema = {
  recordType: { type: "STRING", allowNull: false },
  linkedRecord: { type: "STRING", allowNull: true },
  period: { type: "STRING", allowNull: false }, // or "DATEONLY"
  farmName: { type: "STRING", allowNull: false },
  fieldName: { type: "STRING", allowNull: true },
  crop: { type: "STRING", allowNull: true },
  areaPlanted: { type: "DECIMAL", allowNull: true },
  areaUnit: { type: "STRING", allowNull: false },
  amountHarvested: { type: "DECIMAL", allowNull: true },
  amountUnit: { type: "STRING", allowNull: true },
  memo: { type: "TEXT", allowNull: true },
  type: { type: "STRING", allowNull: false },
  firstHarvest: { type: "STRING", allowNull: true }, // or "DATE"
  area: { type: "DECIMAL", allowNull: false },
  inputs: { type: "JSON", allowNull: true },
  acresCleared: { type: "DECIMAL", allowNull: true },
  acresPlanted: { type: "DECIMAL", allowNull: true },
};

const AddCropModal = ({
  isOpen,
  onClose,
  onSubmit,
  crop,
  mode,
  setMode,
  currentIndex,
  onDelete,
  ual,
}) => {
  const defaultCropData = {
    recordType: "",
    linkedRecord: "",
    period: "",
    farmName: "",
    fieldName: "",
    crop: "",
    areaPlanted: "",
    areaUnit: "",
    amountHarvested: "",
    amountUnit: "",
    memo: "",
    type: "",
    firstHarvest: "",
    area: "",
    acresCleared: "",
    acresPlanted: "",
    inputs: { "": "" },
  };

  const [currentMode, setCurrentMode] = useState(mode);
  const [editableCropData, setEditableCropData] = useState({
    ...defaultCropData,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectData, setSelectData] = useState([]);

  useEffect(() => {
    const fetchCropFields = async () => {
      if (isOpen) {
        setIsLoading(true);
        try {
          const response = await fetch(
            "https://api.growingprosperity.farm/api/v1/crop/fields"
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setSelectData(data.data);
          console.log(data.data);
        } catch (error) {
          console.error("Could not fetch crop fields data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchCropFields();
  }, [isOpen]);

  useEffect(() => {
    if (["edit", "show"].includes(mode) && crop) {
      setEditableCropData((prevData) => ({
        ...crop,
        inputs:
          typeof crop.inputs === "string"
            ? JSON.parse(crop.inputs)
            : crop.inputs || { "": "" },
      }));
    } else if (mode === "add") {
      setEditableCropData(defaultCropData);
    }
    setCurrentMode(mode);
  }, [crop, mode]);

  useEffect(() => {
    if (
      !editableCropData.inputs ||
      Object.keys(editableCropData.inputs).length === 0
    ) {
      setEditableCropData((prevData) => ({
        ...prevData,
        inputs: { "": "" },
      }));
    }
  }, [editableCropData.inputs]);

  const handleEdit = () => {
    setMode("edit");
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, unable to submit document");
      setIsLoading(false);
      return;
    }
    try {
      const id = crop.id;

      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/crop/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.success) {
        setIsLoading(false);
        onClose();
        toast.success("Data deleted successfully");
        onDelete(currentIndex);
      } else {
        // logout
      }
      console.log("API Response:", responseData);
    } catch (error) {
      console.error("Failed to submit document:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableCropData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputChange = (e, key, field) => {
    const { value } = e.target;
    setEditableCropData((prevData) => {
      const inputs = { ...prevData.inputs };
      if (field === "key") {
        const newKey = value;
        if (newKey !== key) {
          inputs[newKey] = inputs[key];
          delete inputs[key];
        }
      } else {
        inputs[key] = value;
      }
      return { ...prevData, inputs };
    });
  };

  const handleAddInput = () => {
    setEditableCropData((prevData) => ({
      ...prevData,
      inputs: { ...prevData.inputs, "": "" },
    }));
  };

  const handleRemoveInput = (key) => {
    setEditableCropData((prevData) => {
      const inputs = { ...prevData.inputs };
      delete inputs[key];
      if (Object.keys(inputs).length === 0) {
        inputs[""] = "";
      }
      return { ...prevData, inputs };
    });
  };

  const splitNameOnUppercase = (name) => {
    const index = Array.from(name).findIndex(
      (char) => char === char.toUpperCase()
    );
    if (index !== -1) {
      const part1 = name.charAt(0).toUpperCase() + name.slice(1, index) + " ";
      const part2 = name.slice(index);
      return `${part1} ${part2}`;
    } else {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const submissionData = {
      ...editableCropData,
      inputs: editableCropData.inputs, // Ensure this is an object
    };

    if (submissionData.recordType === "New") {
      submissionData.linkedRecord = " ";
    }

    if (["edit", "add"].includes(currentMode)) {
      onSubmit(submissionData, currentIndex);
    }
    onClose();
  };

  const renderFormContent = () => {
    const excludeFieldsInShowMode = ["id", "userId"];
    const excludeFieldsInEditMode = ["id", "userId", "createdAt", "updatedAt"];

    const dynamicOptions = selectData.length > 0 ? selectData[0] : {};
    const sortedKeys = Object.keys(editableCropData).sort((a, b) => {
      if (a === "inputs") return 1;
      if (b === "inputs") return -1;
      return 0;
    });

    return sortedKeys.map((key) => {
      if (currentMode === "show" && excludeFieldsInShowMode.includes(key)) {
        return null;
      }

      if (currentMode === "edit" && excludeFieldsInEditMode.includes(key)) {
        return null;
      }

      const isRequired = !schema[key]?.allowNull;

      if (currentMode === "show") {
        if (key === "inputs") {
          const inputs = editableCropData[key];
          const hasInputs =
            inputs &&
            Object.keys(inputs).length > 0 &&
            !(Object.keys(inputs).length === 1 && inputs[""] === "");
          return (
            <div className="input-box" key={key}>
              <label className="form-label">{splitNameOnUppercase(key)}:</label>
              <div className="form-text">
                {hasInputs ? (
                  Object.entries(inputs).map(
                    ([inputKey, inputValue], index) => (
                      <div key={index}>
                        <strong>{inputKey}:</strong> {inputValue}
                      </div>
                    )
                  )
                ) : (
                  <p className="form-text">null</p>
                )}
              </div>
            </div>
          );
        } else {
          const label =
            key === "type"
              ? "Crop Type"
              : key === "firstHarvest"
              ? "Expected Harvest"
              : splitNameOnUppercase(key);
          return (
            <div className="input-box" key={key}>
              <label className="form-label">{label}:</label>
              <p className="form-text">
                {typeof editableCropData[key] === "object"
                  ? JSON.stringify(editableCropData[key])
                  : editableCropData[key] || "null"}
              </p>
            </div>
          );
        }
      }

      if (key === "period" || key === "firstHarvest") {
        const label =
          key === "firstHarvest"
            ? "Expected Harvest"
            : splitNameOnUppercase(key);
        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {label}
            </label>
            <input
              type="date"
              id={key}
              name={key}
              className="input form-input"
              value={editableCropData[key] || ""}
              onChange={handleChange}
              required={isRequired}
            />
          </div>
        );
      }

      if (key === "recordType" && currentMode === "add") {
        const recordTypeOptions = dynamicOptions["recordType"] || [
          "New",
          "Edit",
          "Update",
        ];
        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <select
              id={key}
              name={key}
              className="select form-select"
              value={editableCropData[key] || ""}
              onChange={handleChange}
              required={isRequired}>
              <option value="">Select an option</option>
              {recordTypeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      } else if (currentMode === "add" && dynamicOptions[key]) {
        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <select
              id={key}
              name={key}
              className="select form-select"
              value={editableCropData[key] || ""}
              onChange={handleChange}
              required={isRequired}>
              <option value="">Select an option</option>
              {dynamicOptions[key].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      } else if (
        [
          "areaPlanted",
          "amountHarvested",
          "area",
          "acresCleared",
          "acresPlanted",
        ].includes(key)
      ) {
        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <input
              type="number"
              step="0.01"
              id={key}
              name={key}
              className="input form-input"
              value={editableCropData[key] || ""}
              onChange={handleChange}
              required={isRequired}
            />
          </div>
        );
      } else if (key === "linkedRecord") {
        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <input
              type="text"
              id={key}
              name={key}
              className="input form-input"
              value={editableCropData[key] || ""}
              onChange={handleChange}
              disabled={editableCropData["recordType"] === "New"}
            />
          </div>
        );
      } else if (key === "inputs") {
        return (
          <div className="input-box inputs-section" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <div className="inputs-container">
              {Object.entries(editableCropData.inputs).map(
                ([inputKey, inputValue], index) => (
                  <div key={index} className="input-pair">
                    <input
                      type="text"
                      className="input form-input"
                      value={inputKey}
                      placeholder="fertilizer"
                      onChange={(e) => handleInputChange(e, inputKey, "key")}
                    />
                    <input
                      type="text"
                      className="input form-input"
                      value={inputValue}
                      placeholder="Ammonium nitrate,Potassium"
                      onChange={(e) => handleInputChange(e, inputKey, "value")}
                    />
                    <FaTrash
                      className="remove-input"
                      onClick={() => handleRemoveInput(inputKey)}
                    />
                  </div>
                )
              )}
              <button
                type="button"
                className="add-input"
                onClick={handleAddInput}>
                <FaPlus /> Add Input
              </button>
            </div>
          </div>
        );
      } else {
        const label = key === "type" ? "Crop Type" : splitNameOnUppercase(key);
        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {label}
            </label>
            {/* Crop Type Select Field */}
            {key === "type" ? (
              <select
                id={key}
                name={key}
                className="select form-select"
                value={editableCropData[key] || ""}
                onChange={handleChange}
                required={isRequired}>
                <option value="">Select an option</option>
                <option value="Seasonal">Seasonal</option>
                <option value="Perennial">Perennial</option>
              </select>
            ) : (
              <input
                type="text"
                id={key}
                name={key}
                className="input form-input"
                value={editableCropData[key] || ""}
                onChange={handleChange}
                required={isRequired}
              />
            )}
            {/* End Crop Type Select Field */}
          </div>
        );
      }
    });
  };

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <div className="modal-image">
          <img className="crop-img" src={crop4} alt="" />
          <div className="overlay">
            <img src={crop2new} alt="" className="overlay-image" />
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-heading">
              {currentMode === "edit"
                ? "Edit Crop"
                : currentMode === "add"
                ? "Add Crop"
                : "Crop Details"}
            </h2>
            <FaTimes className="modal-close" onClick={onClose} />
          </div>
          <form onSubmit={handleSubmit} className="modal-form">
            {renderFormContent()}
            {currentMode === "show" && ual?.activeUser && (
              <div className="show-btns">
                <button
                  type="button"
                  className="button-common edit-button"
                  onClick={handleEdit}>
                  Edit
                </button>
                <button
                  type="button"
                  className="button-common delete-btn"
                  onClick={handleDelete}>
                  Delete
                </button>
              </div>
            )}
            {currentMode !== "show" && (
              <button type="submit" className="button-common submit-btn">
                {currentMode === "add" ? "Add" : "Update"}
              </button>
            )}
            {isLoading && <Loader isLoading={isLoading} />}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCropModal;
