import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";

import { UALProvider } from "ual-reactjs-renderer";
import { Anchor } from "ual-anchor";

import Sidebar from "./components/sidebar/Sidebar";
import Dashboard from "./components/dashboard/Dashboard";
import Document from "./components/document/Document";
import Setting from "./components/setting/Setting";
import Help from "./components/help/Help";
import Crop from "./components/crop/Crop";
import Employment from "./components/employment/Employment";
import Expense from "./components/expense/Expense";
import Farm from "./components/farm/Farm";
import Income from "./components/income/Income";
import { ToastContainer } from "react-toastify";
import CropManagement from "./pages/managementPages/cropManagement.jsx/CropManagement";
import DocumentManagement from "./pages/managementPages/documentManagement/DocumentManagement";
import EmploymentManagement from "./pages/managementPages/employmentManagement/EmploymentManagement";
import ExpenseManagement from "./pages/managementPages/expenseManagement/ExpenseManagement";
import FarmManagement from "./pages/managementPages/farmManagement/FarmManagement";
import IncomeManagement from "./pages/managementPages/incomeManagement/IncomeManagement";
import Login from "./pages/login/Login";
import Dummy from "./pages/others/Dummy";

// Use environment variables
const eosChain = {
  chainId: process.env.REACT_APP_CHAINID,
  rpcEndpoints: [
    {
      protocol: process.env.REACT_APP_NETWORK_PROTOCOL,
      host: process.env.REACT_APP_RPC,
      port: process.env.REACT_APP_NETWORK_PORT,
    },
  ],
};

// Define appName
const appName = "Green Earth Agro";

// Initialize Anchor Authenticator
const anchor = new Anchor([eosChain], {
  appName: appName,
  // ... other Anchor options
});

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  useEffect(() => {
    // Check for token in localStorage to keep the user logged in via email/password login
    const emailPasswordToken = localStorage.getItem("emailPasswordToken");
    if (emailPasswordToken) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = (token) => {
    console.log("Logging in, setting isAuthenticated to true");
    localStorage.setItem("emailPasswordToken", token); // Save token to local storage
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    console.log("Logging out, setting isAuthenticated to false");
    localStorage.removeItem("emailPasswordToken"); // Remove token from local storage
    setIsAuthenticated(false);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  console.log("Rendering App, isAuthenticated:", isAuthenticated);

  return (
    <UALProvider
      chains={[eosChain]}
      authenticators={[anchor]}
      appName={appName}>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <div className="app">
          <AppContent
            isAuthenticated={isAuthenticated}
            handleLogout={handleLogout}
            handleLogin={handleLogin}
            isSidebarVisible={isSidebarVisible}
            toggleSidebar={toggleSidebar}
          />
        </div>
      </Router>
    </UALProvider>
  );
};

const AppContent = ({
  isAuthenticated,
  handleLogout,
  handleLogin,
  isSidebarVisible,
  toggleSidebar,
}) => {
  const location = useLocation();

  // Determine if we are on the dashboard
  const isDashboard =
    location.pathname === "/dashboard" || location.pathname === "/";

  return (
    <>
      {(isAuthenticated || isDashboard) && (
        <Sidebar
          isAuthenticated={isAuthenticated}
          onLogout={handleLogout}
          className="Sidebar_App"
          isSidebarVisible={isSidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}
      <div
        className={`page-content ${
          isAuthenticated
            ? isSidebarVisible
              ? "expanded"
              : "collapsed"
            : "full-width"
        }`}>
        <Routes>
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate replace to="/dashboard" />
              ) : (
                <Login onLogin={handleLogin} />
              )
            }
          />
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route
            path="/dashboard"
            element={<Dashboard isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/crop"
            element={
              isAuthenticated ? <Crop /> : <Navigate replace to="/login" />
            }
          />
          <Route
            path="/document"
            element={
              isAuthenticated ? <Document /> : <Navigate replace to="/login" />
            }
          />
          <Route
            path="/employment"
            element={
              isAuthenticated ? (
                <Employment />
              ) : (
                <Navigate replace to="/login" />
              )
            }
          />
          <Route
            path="/expense"
            element={
              isAuthenticated ? <Expense /> : <Navigate replace to="/login" />
            }
          />
          <Route
            path="/farm"
            element={
              isAuthenticated ? <Farm /> : <Navigate replace to="/login" />
            }
          />
          <Route
            path="/income"
            element={
              isAuthenticated ? <Income /> : <Navigate replace to="/login" />
            }
          />
          <Route
            path="/manage-crop"
            element={
              isAuthenticated ? (
                <CropManagement />
              ) : (
                <Navigate replace to="/login" />
              )
            }
          />
          <Route
            path="/manage-document"
            element={
              isAuthenticated ? (
                <DocumentManagement />
              ) : (
                <Navigate replace to="/login" />
              )
            }
          />
          <Route
            path="/manage-expense"
            element={
              isAuthenticated ? (
                <ExpenseManagement />
              ) : (
                <Navigate replace to="/login" />
              )
            }
          />
          <Route
            path="/manage-farm"
            element={
              isAuthenticated ? (
                <FarmManagement />
              ) : (
                <Navigate replace to="/login" />
              )
            }
          />
          <Route
            path="/manage-income"
            element={
              isAuthenticated ? (
                <IncomeManagement />
              ) : (
                <Navigate replace to="/login" />
              )
            }
          />
          <Route
            path="/setting"
            element={
              isAuthenticated ? <Setting /> : <Navigate replace to="/login" />
            }
          />
          <Route
            path="/help"
            element={
              isAuthenticated ? <Help /> : <Navigate replace to="/login" />
            }
          />
          <Route
            path="/dummy"
            element={
              isAuthenticated ? <Dummy /> : <Navigate replace to="/login" />
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default App;
