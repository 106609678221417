import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import "./AddDocumentModal.css";
import crop4 from "../../../images/crop4.jpeg";
import crop2new from "../../../images/crop2new.jpg";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";

const AddDocumentModal = ({
  isOpen,
  onClose,
  onSubmit,
  document,
  mode,
  setMode,
  currentIndex,
  onDelete,
  ual,
}) => {
  const defaultDocumentData = {
    recordType: "",
    linkedRecord: "",
    documentName: "",
    documentType: "",
    farmName: "",
    fieldName: "",
    effectiveDate: "",
    expirationDate: "",
    status: "",
    firstParty: "",
    secondParty: "",
    registrar: "",
    registrationNumber: "",
    registrationHash: "",
    dataRecord: "",
    expenseData: "",
    memo: "",
  };

  const [currentMode, setCurrentMode] = useState(mode);
  const [isLoading, setIsLoading] = useState(false);
  const [editableDocumentData, setEditableDocumentData] = useState({
    ...defaultDocumentData,
  });
  const [selectData, setSelectData] = useState([]);

  useEffect(() => {
    const fetchCropFields = async () => {
      if (isOpen) {
        setIsLoading(true);
        try {
          const response = await fetch(
            "https://api.growingprosperity.farm/api/v1/document/fields"
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setSelectData(data.data);
          console.log(data.data);
        } catch (error) {
          console.error("Could not fetch crop fields data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchCropFields();
  }, [isOpen]);

  console.log("Select Data", selectData);
  console.log("Current index", currentIndex);

  useEffect(() => {
    if (["edit", "show"].includes(mode) && document) {
      setEditableDocumentData(document);
    } else if (mode === "add") {
      setEditableDocumentData(defaultDocumentData);
    }
    setCurrentMode(mode);
  }, [document, mode]);

  const handleEdit = () => {
    setMode("edit");
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, unable to submit document");
      setIsLoading(false);
      return;
    }
    try {
      const id = document.id; // Ensure you have the correct identifier for the document

      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/document/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.success) {
        toast.success("Document deleted successfully");
        onDelete(currentIndex); // This function needs to be passed from the parent component
      } else {
        toast.error(`Error: ${responseData.message}`);
      }
    } catch (error) {
      console.error("Failed to delete document:", error);
      toast.error("Failed to delete document.");
    } finally {
      setIsLoading(false);
      onClose(); // Close the modal
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableDocumentData((prevData) => ({ ...prevData, [name]: value }));
  };

  const splitNameOnUppercase = (name) => {
    const index = Array.from(name).findIndex(
      (char) => char === char.toUpperCase()
    );
    // console.log("INDEX", index);
    if (index !== -1) {
      const part1 = name.charAt(0).toUpperCase() + name.slice(1, index) + " ";

      const part2 = name.slice(index);
      // console.log("part name", `${part1} ${part2}`);
      return `${part1} ${part2}`;
    } else {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const submissionData = { ...editableDocumentData };

    // If 'recordType' is 'New', ensure 'linkedRecord' is an empty string
    if (submissionData.recordType === "New") {
      submissionData.linkedRecord = " ";
    }

    if (["edit", "add"].includes(currentMode)) {
      onSubmit(submissionData, currentIndex);
    }
    onClose();
  };

  if (!isOpen) return null;

  const renderFormContent = () => {
    // Initialize dynamic options based on the selectData provided from the fetch call
    const dynamicOptions = selectData.length > 0 ? selectData[0] : {};

    return Object.keys(editableDocumentData).map((key) => {
      // Exclude fields in "edit" mode
      if (
        currentMode === "edit" &&
        ["id", "userId", "createdAt", "updatedAt"].includes(key)
      ) {
        return null;
      }
      // Exclude fields in "show" mode
      if (currentMode === "show" && ["id", "userId"].includes(key)) {
        return null;
      }

      // Exclude "linkedRecord" if "recordType" is "New"
      if (
        key === "linkedRecord" &&
        editableDocumentData["recordType"] === "New"
      ) {
        return null;
      }

      // Handle the "recordType" field with dynamic or predefined options in "add" mode
      if (key === "recordType" && currentMode === "add") {
        const recordTypeOptions = ["New", "Edit", "Update"]; // Assuming these are the options you want

        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <select
              id={key}
              name={key}
              className="select form-select"
              value={editableDocumentData[key] || ""}
              onChange={handleChange}
              required>
              <option value="">Select an option</option>
              {recordTypeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      }

      // Dynamic select fields for other specified fields in "add" mode
      if (currentMode === "add" && dynamicOptions[key]) {
        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <select
              id={key}
              name={key}
              className="select form-select"
              value={editableDocumentData[key] || ""}
              onChange={handleChange}
              required>
              <option value="">Select an option</option>
              {dynamicOptions[key].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      }

      // Default input handling for text and date types
      return (
        <div className="input-box" key={key}>
          <label htmlFor={key} className="form-label">
            {splitNameOnUppercase(key)}
          </label>
          {currentMode !== "show" ? (
            <input
              type={key.includes("Date") ? "date" : "text"}
              id={key}
              name={key}
              className="input form-input"
              value={editableDocumentData[key] || ""}
              onChange={handleChange}
              required={currentMode !== "show"}
            />
          ) : (
            <p style={{ marginTop: "0" }}>{editableDocumentData[key]}</p>
          )}
        </div>
      );
    });
  };

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      {isLoading && <Loader />}{" "}
      {/* This will show the Loader when isLoading is true */}
      <div className="modal-container">
        <div className="modal-image">
          <img className="crop-img" src={crop4} alt="" />
          <div className="overlay">
            {" "}
            {/* Corrected from class to className */}
            <img src={crop2new} alt="" className="overlay-image" />
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-heading">
              {currentMode === "edit"
                ? "Edit Document"
                : currentMode === "add"
                ? "Add Document"
                : "Document Details"}
            </h2>
            <FaTimes className="modal-close" onClick={onClose} />
          </div>
          <form onSubmit={handleSubmit} className="modal-form">
            {renderFormContent()}
            {currentMode === "show" && ual?.activeUser && (
              <div className="show-btns">
                <button
                  type="button"
                  className="button-common edit-btn"
                  onClick={handleEdit}>
                  Edit
                </button>
                <button
                  type="button"
                  className="button-common delete-btn"
                  onClick={handleDelete}>
                  Delete
                </button>
              </div>
            )}
            {currentMode !== "show" && (
              <button type="submit" className="button-common submit-btn">
                {currentMode === "add" ? "Add" : "Update"}
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddDocumentModal;
