import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import "./AddEmploymentModal.css";
import crop4 from "../../../images/crop4.jpeg";
import crop2new from "../../../images/crop2new.jpg";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";

const AddEmploymentModal = ({
  isOpen,
  onClose,
  onSubmit,
  employment,
  mode,
  setMode,
  currentIndex,
  onDelete,
  ual,
}) => {
  const defaultEmploymentData = {
    recordType: "",
    linkedRecord: "",
    period: "",
    totalEmployment: "",
    male: "",
    female: "",
    expenseData: "",
    memo: "",
  };

  const [currentMode, setCurrentMode] = useState(mode);
  const [isLoading, setIsLoading] = useState(false);
  const [editableEmploymentData, setEditableEmploymentData] = useState(
    defaultEmploymentData
  );

  useEffect(() => {
    if (["edit", "show"].includes(mode) && employment) {
      setEditableEmploymentData(employment);
    } else if (mode === "add") {
      setEditableEmploymentData(defaultEmploymentData);
    }
    setCurrentMode(mode);
  }, [employment, mode]);

  const handleEdit = () => {
    setMode("edit"); // Update the mode to 'edit'
  };

  const handleDelete = async () => {
    setIsLoading(true); // Begin loading
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, unable to submit document");
      setIsLoading(false); // End loading
      return;
    }
    try {
      const id = employment.id;

      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/employment/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.success) {
        toast.success("Data deleted successfully");
        onDelete(currentIndex);
      } else {
        toast.error(`Error: ${responseData.message}`);
      }
    } catch (error) {
      console.error("Failed to delete employment:", error);
      toast.error("Failed to delete employment.");
    } finally {
      setIsLoading(false); // End loading
      onClose(); // Close the modal
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableEmploymentData((prevData) => ({ ...prevData, [name]: value }));
  };

  const splitNameOnUppercase = (name) => {
    const index = Array.from(name).findIndex(
      (char) => char === char.toUpperCase()
    );
    console.log("INDEX", index);
    if (index !== -1) {
      const part1 = name.charAt(0).toUpperCase() + name.slice(1, index) + " ";

      const part2 = name.slice(index);
      console.log("part name", `${part1} ${part2}`);
      return `${part1} ${part2}`;
    } else {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (["edit", "add"].includes(currentMode)) {
      onSubmit(editableEmploymentData, currentIndex);
    }
    onClose();
  };

  if (!isOpen) return null;

  const renderFormContent = () => {
    return Object.keys(editableEmploymentData).map((key) => {
      // Exclude fields for "edit" mode
      if (
        currentMode === "edit" &&
        ["id", "userId", "createdAt", "updatedAt"].includes(key)
      ) {
        return null;
      }
      // Exclude fields for "show" mode
      if (currentMode === "show" && ["id", "userId"].includes(key)) {
        return null;
      }

      return (
        <div className="input-box" key={key}>
          <label htmlFor={key}>{splitNameOnUppercase(key)}</label>
          {currentMode !== "show" ? (
            // Render as input fields for 'edit' and 'add' modes
            <input
              type={
                key.includes("Date") || key.includes("period") ? "date" : "text"
              }
              id={key}
              name={key}
              value={editableEmploymentData[key] || ""}
              onChange={handleChange}
              required={currentMode !== "show"}
            />
          ) : (
            // Render as text for 'show' mode, and do not render 'id' and 'userId'
            <p style={{ marginTop: "0" }}>{editableEmploymentData[key]}</p>
          )}
        </div>
      );
    });
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <div className="modal-image">
          <img className="crop-img" src={crop4} alt="" />
          <div class="overlay">
            <img src={crop2new} alt="" className="overlay-image" />
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-heading">
              {currentMode === "edit"
                ? "Edit Employment Record"
                : currentMode === "add"
                ? "Add Employment Record"
                : "Employment Details"}
            </h2>
            <FaTimes className="modal-close" onClick={onClose} />
          </div>
          <form onSubmit={handleSubmit} className="modal-form">
            {renderFormContent()}
            {currentMode === "show" && ual?.activeUser && (
              <div className="show-btns">
                <button
                  type="button"
                  className="button-common edit-btn"
                  onClick={handleEdit}>
                  Edit
                </button>
                <button
                  type="button"
                  className="button-common delete-btn"
                  onClick={handleDelete}>
                  Delete
                </button>
              </div>
            )}
            {currentMode !== "show" && (
              <button type="submit" className="button-common submit-btn">
                {currentMode === "add" ? "Add" : "Update"}
              </button>
            )}
            {isLoading && <Loader isLoading={isLoading} />}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEmploymentModal;
