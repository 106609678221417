import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";
import img3 from "../../images/img3.jpg";
import img8 from "../../images/img8.jpg";
import img10 from "../../images/img10.jpg";
import img15 from "../../images/img15.jpg";
import img12 from "../../images/img12.jpg";
import img13 from "../../images/img13.jpg";

const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 200,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: true,
    centerMode: true,
    centerPadding: "0",
  };

  return (
    <div className="carousel">
      <Slider {...settings}>
        <div className="image-container">
          <img src={img3} alt="Crop" />
        </div>
        <div className="image-container">
          <img src={img8} alt="Crop" />
        </div>
        <div className="image-container">
          <img src={img10} alt="Crop" />
        </div>
        <div className="image-container">
          <img src={img12} alt="Crop" />
        </div>
        <div className="image-container">
          <img src={img13} alt="Crop" />
        </div>
        <div className="image-container">
          <img src={img15} alt="Crop" />
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
