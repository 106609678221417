import React, { useEffect, useState } from "react";
import "./Expense.css";
import { FaPlus } from "react-icons/fa";
import Header from "../header/Header";
import AddExpenseModal from "../modal/addExpenseModal/AddExpenseModal";
import BlockchainService from "../../services/BlockchainService";
import { withUAL } from "ual-reactjs-renderer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader/Loader";
import ExpanseFilters from "../searchFilters/expanseFilters/ExpanseFilters";
import { FaInfoCircle } from "react-icons/fa";

const blockchainService = new BlockchainService();

const Expense = ({ ual }) => {
  const [expenses, setExpenses] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentExpense, setCurrentExpense] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [modalMode, setModalMode] = useState("add");
  const [currentPage, setCurrentPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [filtersActive, setFiltersActive] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.growingprosperity.farm/api/v1/user/log/expense"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setIsNextPage(data.nextPage);
        setExpenses(data.data);
      } catch (error) {
        console.error("Error fetching expense data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const openModal = (expense = null, index = null, mode = "add") => {
    setModalOpen(true);
    setCurrentExpense(expense);
    setCurrentIndex(index);
    setModalMode(mode);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentExpense(null);
    setCurrentIndex(null);
  };

  console.log("current expense", currentExpense);

  const handleExpenseSubmit = async (submittedExpenseData, index) => {
    setIsLoading(true);

    // Format dates or handle any specific expense data preprocessing here
    // If no need to reformat the dates for expense data, then skip this part

    // Remove certain fields from formattedSubmissionData if in "edit" mode
    if (modalMode === "edit") {
      const { id, userId, createdAt, updatedAt, ...rest } =
        submittedExpenseData;
      submittedExpenseData = rest;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, unable to submit expense data");
      setIsLoading(false);
      return;
    }

    const apiUrl =
      modalMode === "edit" && currentExpense?.id
        ? `https://api.growingprosperity.farm/api/v1/user/log/expense/${currentExpense.id}`
        : "https://api.growingprosperity.farm/api/v1/user/log/expense";

    console.log("API", apiUrl);

    const httpMethod = modalMode === "edit" ? "PUT" : "POST";

    try {
      const response = await fetch(apiUrl, {
        method: httpMethod,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify(submittedExpenseData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.success) {
        const transactionData = responseData.tnx; // This is a placeholder and should match your actual API response

        // Submit the transaction to the blockchain
        const result = await blockchainService.pushTransaction(
          transactionData,
          ual,
          "invest", // The actual action name here depends on your smart contract
          {} // Any additional options required for the transaction
        );

        if (result.success) {
          toast.success(
            modalMode === "edit"
              ? "Expense updated successfully"
              : "Expense added successfully"
          );
        }

        // Update the expenses array or state as needed here
        if (modalMode === "edit") {
          setExpenses(
            expenses.map((expense, idx) =>
              idx === index ? submittedExpenseData : expense
            )
          );
        } else {
          setExpenses([submittedExpenseData, ...expenses]);
        }
      } else {
        toast.error(`Error: ${responseData.message}`);
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.error("Failed to submit expense data:", error);
      toast.error("Failed to submit expense data.");
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const paginate = async (pageNumber) => {
    setIsLoading(true); // Set loading to true when paginating
    try {
      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/expense?page=${pageNumber}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Get response of crops", data);
      setIsNextPage(data.nextPage);
      setCurrentPage(pageNumber);
      setExpenses(data.data);
    } catch (error) {
      console.error("Error fetching crop data:", error);
    } finally {
      setIsLoading(false); // Set loading to false after paginating
    }
  };

  const deleteExpense = (expenseIndex) => {
    const updatedExpenses = expenses.filter(
      (_, index) => index !== expenseIndex
    );
    setExpenses(updatedExpenses);
  };

  const handleSearch = async (filters) => {
    setIsLoading(true);
    try {
      // Convert all filter values to strings
      const queryString = Object.entries(filters)
        .filter(([_, value]) => value !== "")
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`
        )
        .join("&");

      console.log("Query String", queryString); // Useful for debugging

      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/expense?${queryString}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.data.length === 0) {
        toast.info("No data has been found for these filters.");
        setFiltersActive(true);
        setFilteredExpenses([]);
      } else {
        setFilteredExpenses(data.data);
        setFiltersActive(true);
      }
    } catch (error) {
      console.error("Error fetching filtered expense data:", error);
      toast.error("An error occurred while fetching the filtered data.");
      setFiltersActive(false);
    } finally {
      setIsLoading(false);
    }
  };

  //  function to handle reset action
  const handleResetFilters = () => {
    setFilteredExpenses([]);
    setFiltersActive(false);
  };

  return (
    <div className="expense">
      <Header ual={ual} />
      <div className="expense-content">
        <div className="search-box">
          <div className="search-box-content">
            <ExpanseFilters
              onSearch={handleSearch}
              onReset={handleResetFilters}
            />
          </div>
        </div>
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : filtersActive ? (
          filteredExpenses.length === 0 ? (
            <div className="no-data-message">No records found.</div>
          ) : (
            <div className="table-container">
              <table className="expense-table">
                <thead>
                  <tr>
                    <th>
                      Record Type <FaInfoCircle />
                    </th>
                    <th>
                      Linked Record <FaInfoCircle />
                    </th>
                    <th>
                      Period <FaInfoCircle />
                    </th>
                    <th>
                      Type <FaInfoCircle />
                    </th>
                    <th>
                      Class <FaInfoCircle />
                    </th>
                    <th>
                      Recipient <FaInfoCircle />
                    </th>
                    <th>
                      Ledger <FaInfoCircle />
                    </th>
                    <th>
                      Record Reference <FaInfoCircle />
                    </th>
                    <th>
                      Amount <FaInfoCircle />
                    </th>
                    <th>
                      Currency <FaInfoCircle />
                    </th>
                    <th>
                      Memo <FaInfoCircle />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredExpenses.map((expense, index) => (
                    <tr
                      key={index}
                      onClick={() => openModal(expense, index, "show")}>
                      <td>{expense.recordType}</td>
                      <td>{expense.linkedRecord}</td>
                      <td>{expense.period}</td>
                      <td>{expense.type}</td>
                      <td>{expense.class}</td>
                      <td>{expense.recipient}</td>
                      <td>{expense.ledger}</td>
                      <td>{expense.recordReference}</td>
                      <td>{expense.amount}</td>
                      <td>{expense.currency}</td>
                      <td>{expense.memo}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        ) : expenses.length === 0 ? (
          <div className="no-data-message">No records found.</div>
        ) : (
          <div className="table-container">
            <table className="expense-table">
              <thead>
                <tr>
                  <th>
                    Record Type <FaInfoCircle />
                  </th>
                  <th>
                    Linked Record <FaInfoCircle />
                  </th>
                  <th>
                    Period <FaInfoCircle />
                  </th>
                  <th>
                    Type <FaInfoCircle />
                  </th>
                  <th>
                    Class <FaInfoCircle />
                  </th>
                  <th>
                    Recipient <FaInfoCircle />
                  </th>
                  <th>
                    Ledger <FaInfoCircle />
                  </th>
                  <th>
                    Record Reference <FaInfoCircle />
                  </th>
                  <th>
                    Amount <FaInfoCircle />
                  </th>
                  <th>
                    Currency <FaInfoCircle />
                  </th>
                  <th>
                    Memo <FaInfoCircle />
                  </th>
                </tr>
              </thead>
              <tbody>
                {expenses.map((expense, index) => (
                  <tr
                    key={index}
                    onClick={() => openModal(expense, index, "show")}>
                    <td>{expense.recordType}</td>
                    <td>{expense.linkedRecord}</td>
                    <td>{expense.period}</td>
                    <td>{expense.type}</td>
                    <td>{expense.class}</td>
                    <td>{expense.recipient}</td>
                    <td>{expense.ledger}</td>
                    <td>{expense.recordReference}</td>
                    <td>{expense.amount}</td>
                    <td>{expense.currency}</td>
                    <td>{expense.memo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="expense-footer">
        <div className="pagination">
          <button
            className={`prev-btn${currentPage === 1 ? " disabled" : ""}`}
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}>
            Previous
          </button>
          <button
            className={`next-btn${!isNextPage ? " disabled" : ""}`}
            onClick={() => paginate(currentPage + 1)}
            disabled={!isNextPage}>
            Next
          </button>
        </div>
        {ual?.activeUser && (
          <button
            className="add-expense-btn"
            onClick={() => openModal(null, null, "add")}>
            <FaPlus /> Add Expense
          </button>
        )}
      </div>
      <AddExpenseModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleExpenseSubmit}
        expense={currentExpense}
        mode={modalMode}
        setMode={setModalMode}
        currentIndex={currentIndex}
        onDelete={deleteExpense}
        ual={ual}
      />
    </div>
  );
};

export default withUAL(Expense);
