import React, { useEffect, useState } from "react";

const CropFilters = ({ onSearch, isOpen }) => {
  const initialState = {
    recordType: "",
    linkedRecord: "",
    period: "",
    farmName: "",
    fieldName: "",
    crop: "",
    areaPlanted: "",
    areaUnit: "",
    amountHarvested: "",
    amountUnit: "",
    memo: "",
    type: "",
    firstHarvest: "",
    area: "",
    inputs: "",
    acresCleared: "",
    acresPlanted: "",
  };

  const [filters, setFilters] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOption, setFilterOption] = useState([]);

  useEffect(() => {
    const fetchCropFields = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://api.growingprosperity.farm/api/v1/crop/fields"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
        if (data.data.length === 0) {
          setFilterOption([]);
        } else {
          setFilterOption(data.data);
        }
        console.log(data.data);
      } catch (error) {
        console.error("Could not fetch crop fields data:", error);
        setFilters([]); // Clear filter options in case of error
      } finally {
        setIsLoading(false);
      }
    };

    fetchCropFields();
  }, []);

  console.log("Filter Option", filterOption);

  const handleFocus = (event) => {
    event.target.type = "date";
  };

  const handleBlur = (event) => {
    if (event.target.value === "") {
      event.target.type = "text";
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [id]: value }));
  };

  const handleSearchClick = () => {
    const searchResults = onSearch(filters);

    if (searchResults.length === 0) {
      alert("No records found");
      setFilters(initialState); // Reset the filters
    }
  };

  const handleResetClick = () => {
    setFilters(initialState);
    onSearch(initialState);
  };

  const options = filterOption.length > 0 ? filterOption[0] : {};

  return (
    <div className="search-container">
      <div className="search-filters">
        <div className="upper-filter">
          <select
            id="recordType"
            className="filter-control"
            value={filters.recordType}
            onChange={handleChange}>
            <option value="">Record Type</option>
            <option value="New">New</option>
            <option value="Old">Old</option>
            <option value="Edited">Edited</option>
          </select>
          <input
            type="text"
            id="linkedRecord"
            className="filter-control"
            value={filters.linkedRecord}
            placeholder="Linked Record"
            onChange={handleChange}
            disabled={filters.recordType === "New"}
          />
          <input
            type="text"
            id="period"
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="filter-control"
            value={filters.period}
            placeholder="Period"
            onChange={handleChange}
          />
          <select
            id="farmName"
            className="filter-control"
            value={filters.farmName}
            onChange={handleChange}>
            <option value="">Farm Name</option>
            {options.farmName &&
              options.farmName.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </select>
          <select
            id="fieldName"
            className="filter-control"
            value={filters.fieldName}
            onChange={handleChange}>
            <option value="">Field Name</option>
            {options.fieldName &&
              options.fieldName.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </select>
          <select
            id="crop"
            className="filter-control"
            value={filters.crop}
            onChange={handleChange}>
            <option value="">Crop</option>
            {options.crop &&
              options.crop.map((crop, index) => (
                <option key={index} value={crop}>
                  {crop}
                </option>
              ))}
          </select>
          <input
            type="number"
            id="areaPlanted"
            className="filter-control"
            value={filters.areaPlanted}
            placeholder="Area Planted"
            onChange={handleChange}
          />
          <select
            id="areaUnit"
            className="filter-control"
            value={filters.areaUnit}
            onChange={handleChange}>
            <option value="">Area Unit</option>
            {options.areaUnit &&
              options.areaUnit.map((unit, index) => (
                <option key={index} value={unit}>
                  {unit}
                </option>
              ))}
          </select>
          <input
            type="number"
            id="amountHarvested"
            className="filter-control"
            value={filters.amountHarvested}
            placeholder="Amount Harvested"
            onChange={handleChange}
          />
          <button
            className="reset-btn"
            type="button"
            onClick={handleResetClick}>
            Reset
          </button>
        </div>
        <div className="lower-filter">
          <select
            id="amountUnit"
            className="filter-control"
            value={filters.amountUnit}
            onChange={handleChange}>
            <option value="">Amount Unit</option>
            {options.amountUnit &&
              options.amountUnit.map((unit, index) => (
                <option key={index} value={unit}>
                  {unit}
                </option>
              ))}
          </select>
          <input
            type="text"
            id="memo"
            className="filter-control"
            value={filters.memo}
            placeholder="Memo"
            onChange={handleChange}
          />
          <select
            id="type"
            className="filter-control"
            value={filters.type}
            onChange={handleChange}>
            <option value="">Crop Type</option>
            <option value="Seasonal">Seasonal</option>
            <option value="Perennial">Perennial</option>
          </select>
          <input
            type="text"
            id="firstHarvest"
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="filter-control"
            value={filters.firstHarvest}
            placeholder="Expected Harvest"
            onChange={handleChange}
          />
          <input
            type="number"
            id="area"
            className="filter-control"
            value={filters.area}
            placeholder="Area"
            onChange={handleChange}
          />
          <textarea
            id="inputs"
            className="filter-control"
            value={filters.inputs}
            placeholder="Inputs"
            onChange={handleChange}
          />
          <input
            type="number"
            id="acresCleared"
            className="filter-control"
            value={filters.acresCleared}
            placeholder="Acres Cleared"
            onChange={handleChange}
          />
          <input
            type="number"
            id="acresPlanted"
            className="filter-control"
            value={filters.acresPlanted}
            placeholder="Acres Planted"
            onChange={handleChange}
          />
          <button
            className="search-btn"
            type="button"
            onClick={handleSearchClick}>
            Search
          </button>
        </div>
      </div>
      {/* {isLoading && <Loader isLoading={isLoading} />} */}
    </div>
  );
};

export default CropFilters;
