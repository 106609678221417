import React, { useEffect, useState } from "react";
import "./DocumentFilters.css";

const DocumentFilters = ({ onSearch, onReset }) => {
  const initialState = {
    recordType: "",
    linkedRecord: "",
    documentName: "",
    documentType: "",
    farmName: "",
    fieldName: "",
    effectiveDate: "",
    expirationDate: "",
    status: "",
    firstParty: "",
    secondParty: "",
    registrar: "",
    registrationNumber: "",
    registrationHash: "",
    dataRecord: "",
    dataExpense: "",
    memo: "",
  };

  const [filters, setFilters] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOption, setFilterOption] = useState([]);

  useEffect(() => {
    const fetchCropFields = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://api.growingprosperity.farm/api/v1/document/fields"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setFilterOption(data.data);
        console.log(data.data);
      } catch (error) {
        console.error("Could not fetch crop fields data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCropFields();
  }, []);

  const handleFocus = (event) => {
    event.target.type = "date";
  };

  const handleBlur = (event) => {
    if (event.target.value === "") {
      event.target.type = "text";
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFilters((prev) => ({ ...prev, [id]: value }));
  };

  const handleSearchClick = () => {
    onSearch(filters);
  };

  const handleResetClick = () => {
    setFilters(initialState);
    onReset();
  };

  const options = filterOption.length > 0 ? filterOption[0] : {};

  return (
    <div className="search-container">
      <div className="search-filters">
        <div className="upper-filter">
          <select
            id="recordType"
            className="filter-control"
            value={filters.recordType}
            onChange={handleChange}>
            <option value="">Record Type</option>
            <option value="new">New</option>
            <option value="old">Old</option>
            <option value="edited">Updated</option>
          </select>
          <input
            type="text"
            id="linkedRecord"
            className="filter-control"
            placeholder="Linked Record"
            value={filters.linkedRecord}
            onChange={handleChange}
          />
          <input
            type="text"
            id="documentName"
            className="filter-control"
            placeholder="Document Name"
            value={filters.documentName}
            onChange={handleChange}
          />

          <select
            id="documentType"
            className="filter-control"
            value={filters.documentType}
            onChange={handleChange}>
            <option value="">Document Type</option>
            {options.documentType &&
              options.documentType.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </select>
          <select
            id="farmName"
            className="filter-control"
            value={filters.farmName}
            onChange={handleChange}>
            <option value="">Farm Name</option>
            {options.farmName &&
              options.farmName.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </select>
          <select
            id="fieldName"
            className="filter-control"
            value={filters.fieldName}
            onChange={handleChange}>
            <option value="">Field Name</option>
            {options.fieldName &&
              options.fieldName.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </select>
          <input
            type="text"
            id="effectiveDate"
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="filter-control"
            placeholder="Effective Date"
            value={filters.effectiveDate}
            onChange={handleChange}
          />
          <input
            type="text"
            id="expirationDate"
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="filter-control"
            placeholder="Expiration Date"
            value={filters.expirationDate}
            onChange={handleChange}
          />

          <button
            className="reset-btn"
            type="button"
            onClick={handleResetClick}>
            Reset
          </button>
        </div>
        <div className="lower-filter">
          <input
            type="text"
            id="firstParty"
            className="filter-control"
            placeholder="First Party"
            value={filters.firstParty}
            onChange={handleChange}
          />
          <input
            type="text"
            id="secondParty"
            className="filter-control"
            placeholder="Second Party"
            value={filters.secondParty}
            onChange={handleChange}
          />
          <input
            type="text"
            id="registrar"
            className="filter-control"
            placeholder="Registrar"
            value={filters.registrar}
            onChange={handleChange}
          />
          <input
            type="text"
            id="registrationNumber"
            className="filter-control"
            placeholder="Registration Number"
            value={filters.registrationNumber}
            onChange={handleChange}
          />
          <input
            type="text"
            id="registrationHash"
            className="filter-control"
            placeholder="Registration Hash"
            value={filters.registrationHash}
            onChange={handleChange}
          />
          <select
            id="status"
            className="filter-control"
            value={filters.status}
            onChange={handleChange}>
            <option value="">Status</option>
            {options.status &&
              options.status.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </select>
          <input
            type="text"
            id="dataExpense"
            className="filter-control"
            placeholder="Data Expense"
            value={filters.dataExpense}
            onChange={handleChange}
          />
          <input
            type="text"
            id="memo"
            className="filter-control"
            placeholder="Memo"
            value={filters.memo}
            onChange={handleChange}
          />
          <button
            className="search-btn"
            type="button"
            onClick={handleSearchClick}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentFilters;
