import React, { useEffect, useState } from "react";
import "./Farm.css";
import { withUAL } from "ual-reactjs-renderer";
import { FaPlus } from "react-icons/fa";
import Header from "../header/Header";
import AddFarmModal from "../modal/addFarmModal/AddFarmModal";
import farmData from "../../data/farmData";
import BlockchainService from "../../services/BlockchainService";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
import FarmFilters from "../searchFilters/farmFilters/FarmFilters";
import { FaInfoCircle } from "react-icons/fa";

const blockchainService = new BlockchainService();

const Farm = ({ ual }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentFarm, setCurrentFarm] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [modalMode, setModalMode] = useState("add");
  const [farms, setFarms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredFarms, setFilteredFarms] = useState([]);
  const [filtersActive, setFiltersActive] = useState(false);

  const openModal = (farm = null, index = null, mode = "add") => {
    setModalOpen(true);
    setCurrentFarm(farm);
    setCurrentIndex(index);
    setModalMode(mode);
  };

  console.log("modal data", currentFarm);

  const closeModal = () => {
    setModalOpen(false);
    setCurrentFarm(null);
    setCurrentIndex(null);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.growingprosperity.farm/api/v1/user/log/farm"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // console.log("Expense Data:", data);
        setIsNextPage(data.next_page);
        setFarms(data.data);
      } catch (error) {
        console.error("Error fetching expense data:", error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    };

    fetchData();
  }, []);

  const handleFarmSubmit = async (submittedFarmData, index) => {
    setIsLoading(true);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, unable to submit farm data");
      setIsLoading(false);
      return;
    }

    // Exclude specific fields for "edit" mode before submission
    let farmDataToSubmit = { ...submittedFarmData };
    if (modalMode === "edit") {
      const { id, userId, createdAt, updatedAt, ...rest } = farmDataToSubmit;
      farmDataToSubmit = rest;
    }

    // Ensure fieldName is a stringified JSON
    if (typeof farmDataToSubmit.fieldName !== "string") {
      farmDataToSubmit.fieldName = JSON.stringify(farmDataToSubmit.fieldName);
    }

    const apiUrl =
      modalMode === "edit" && currentFarm?.id
        ? `https://api.growingprosperity.farm/api/v1/user/log/farm/${currentFarm.id}`
        : "https://api.growingprosperity.farm/api/v1/user/log/farm";

    const httpMethod = modalMode === "edit" ? "PUT" : "POST";

    try {
      const response = await fetch(apiUrl, {
        method: httpMethod,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify(farmDataToSubmit),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.success) {
        toast.success(
          modalMode === "edit"
            ? "Farm updated successfully"
            : "Farm added successfully"
        );
        // Update the farms array or state as needed here
        if (modalMode === "edit") {
          setFarms(
            farms.map((farm, idx) =>
              idx === index
                ? { ...submittedFarmData, id: farms[index]?.id }
                : farm
            )
          );
        } else {
          setFarms([{ ...submittedFarmData, id: responseData.id }, ...farms]);
        }
      } else {
        toast.error(`Error: ${responseData.message}`);
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.error("Failed to submit farm data:", error);
      toast.error("Failed to submit farm data.");
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const paginate = async (pageNumber) => {
    setIsLoading(true); // Set loading to true when paginating
    try {
      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/farm?page=${pageNumber}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Get response of crops", data);
      setIsNextPage(data.next_page);
      setCurrentPage(pageNumber);
      setFarms(data.data);
    } catch (error) {
      console.error("Error fetching crop data:", error);
    } finally {
      setIsLoading(false); // Set loading to false after paginating
    }
  };

  const renderFieldNames = (fieldName) => {
    console.log("FIELD NAME", fieldName);
    const fieldLines = Object.entries(fieldName).map(([plot, crop], index) => (
      <span key={index}>{`${crop}`}</span>
    ));
    return <div>{fieldLines}</div>;
  };

  const deleteCrop = (index) => {
    const updatedCrops = [...farms];
    updatedCrops.splice(index, 1);
    setFarms(updatedCrops);
  };

  const handleSearch = async (filters) => {
    setIsLoading(true);
    try {
      // Construct the query string from filters
      const queryString = Object.entries(filters)
        .filter(([_, value]) => value)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/farm?${queryString}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.data.length === 0) {
        toast.info("No data has been found for these filters.");
        setFiltersActive(true);
        setFilteredFarms([]);
      } else {
        setFilteredFarms(data.data);
        setFiltersActive(true);
      }
    } catch (error) {
      console.error("Error fetching farm data:", error);
      toast.error("An error occurred while fetching the filtered data.");
      setFiltersActive(false);
    } finally {
      setIsLoading(false);
    }
  };

  //  function to handle reset action
  const handleResetFilters = () => {
    setFilteredFarms([]);
    setFiltersActive(false);
  };

  console.log("Filtered farms", filteredFarms);

  const dataToShow = filteredFarms.length > 0 ? filteredFarms : farms;

  return (
    <div className="farm">
      <Header ual={ual} />
      <div className="farm-content">
        <div className="search-box">
          <div className="search-box-content">
            <FarmFilters onSearch={handleSearch} onReset={handleResetFilters} />
          </div>
        </div>
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : filtersActive ? (
          filteredFarms.length === 0 ? (
            <div className="no-data-message">No records found.</div>
          ) : (
            <div className="table-container">
              <table className="farm-table">
                <thead>
                  <tr>
                    <th>
                      Record Type <FaInfoCircle />
                    </th>
                    <th>
                      Linked Record <FaInfoCircle />
                    </th>
                    <th>
                      Farm Name <FaInfoCircle />
                    </th>
                    <th>
                      Field Name <FaInfoCircle />
                    </th>
                    <th>
                      Field <FaInfoCircle />
                    </th>
                    <th>
                      Land Owner <FaInfoCircle />
                    </th>
                    <th>
                      Memo <FaInfoCircle />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredFarms.map((farm, index) => (
                    <tr
                      key={index}
                      onClick={() => openModal(farm, index, "show")}>
                      <td>{farm.recordType}</td>
                      <td>{farm.linkedRecord}</td>
                      <td>{farm.farmName}</td>
                      <td>{farm.fieldName}</td>
                      <td>{farm.field}</td>
                      <td>{farm.landOwner}</td>
                      <td>{farm.memo}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        ) : farms.length === 0 ? (
          <div className="no-data-message">No records found.</div>
        ) : (
          <div className="table-container">
            <table className="farm-table">
              <thead>
                <tr>
                  <th>
                    Record Type <FaInfoCircle />
                  </th>
                  <th>
                    Linked Record <FaInfoCircle />
                  </th>
                  <th>
                    Farm Name <FaInfoCircle />
                  </th>
                  <th>
                    Field Name <FaInfoCircle />
                  </th>
                  <th>
                    Field <FaInfoCircle />
                  </th>
                  <th>
                    Land Owner <FaInfoCircle />
                  </th>
                  <th>
                    Memo <FaInfoCircle />
                  </th>
                </tr>
              </thead>
              <tbody>
                {farms.map((farm, index) => (
                  <tr
                    key={index}
                    onClick={() => openModal(farm, index, "show")}>
                    <td>{farm.recordType}</td>
                    <td>{farm.linkedRecord}</td>
                    <td>{farm.farmName}</td>
                    <td>{farm.fieldName}</td>
                    <td>{farm.field}</td>
                    <td>{farm.landOwner}</td>
                    <td>{farm.memo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="farm-footer">
        <div className="pagination">
          <button
            className={`prev-btn${currentPage === 1 ? " disabled" : ""}`}
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}>
            Previous
          </button>
          <button
            className={`next-btn${!isNextPage ? " disabled" : ""}`}
            onClick={() => paginate(currentPage + 1)}
            disabled={!isNextPage}>
            Next
          </button>
        </div>
        {ual?.activeUser && (
          <button
            className="add-farm-btn"
            onClick={() => openModal(null, null, "add")}>
            <FaPlus /> Add Farm
          </button>
        )}
      </div>
      <AddFarmModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleFarmSubmit}
        farm={currentFarm}
        mode={modalMode}
        setMode={setModalMode}
        currentIndex={currentIndex}
      />
    </div>
  );
};

export default withUAL(Farm);
