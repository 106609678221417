import React from "react";
import HashLoader from "react-spinners/HashLoader";

const Loader = ({ isLoading }) => {
  return (
    <div className="loader-wrapper">
      <HashLoader
        color={"var(--color-secondary)"}
        loading={isLoading}
        size={80}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          height: "100vh",
          transform: "translate(-50%, -50%)",
          backgroundColor: isLoading ? "rgba(0,0,0,0.4)" : "transparent",
        }}
      />
    </div>
  );
};

export default Loader;
