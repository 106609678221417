import React from "react";
import "./EmploymentManagement.css";
import { withUAL } from "ual-reactjs-renderer";
import Header from "../../../components/header/Header";

const EmploymentManagement = ({ ual }) => {
  console.log("UAL in employment-Manag", ual);
  return (
    <div className="employment-Management">
      <Header ual={ual} />
      <div className="employment-management-content">
        This is employment Management
      </div>
    </div>
  );
};

export default withUAL(EmploymentManagement);
