import React from "react";
import setting from "../../images/setting.svg";
import "./Setting.css";
import Header from "../header/Header";

const Setting = () => {
  return (
    <>
      <Header />
      <div className="setting">
        <img src={setting} alt="" className="setting_pic" />
        <h3 className="setting_text">Setting Coming Soon.....</h3>
      </div>
    </>
  );
};

export default Setting;
