import React, { useEffect, useState } from "react";
import "./DocumentManagement.css";
import { withUAL } from "ual-reactjs-renderer";
import Header from "../../../components/header/Header";
import Loader from "../../../components/loader/Loader";

const DocumentManagement = ({ ual }) => {
  const [documentFields, setDocumentFields] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDocumentFields = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://api.growingprosperity.farm/api/v1/document/fields"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setDocumentFields(data.data);
      } catch (error) {
        console.error("Could not fetch document fields data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDocumentFields();
  }, []);

  const handleEditMode = () => {
    if (isEditMode) {
      saveDocumentFields();
    }
    setIsEditMode(!isEditMode);
  };

  const saveDocumentFields = async () => {
    setIsLoading(true);
    console.log("Saving crop fields");
    const token = localStorage.getItem("token");

    for (const field of documentFields) {
      try {
        const response = await fetch(
          `https://api.growingprosperity.farm/api/v1/document/fields/${field.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              // Include the token in the 'x-auth-token' header
              "x-auth-token": token,
            },
            body: JSON.stringify(field),
          }
        );

        if (!response.ok) {
          // Handle response errors
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        // Optionally handle the response data
        const data = await response.json();
        console.log("Update successful for field with id:", field.id, data);
      } catch (error) {
        console.error("Could not update document fields data:", error);
      }
    }
    setIsLoading(false);
    setIsEditMode(false);
  };

  const formatFieldName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleChange = (index, key, newValue, subIndex) => {
    const updatedFields = [...documentFields];
    if (Array.isArray(updatedFields[index][key])) {
      updatedFields[index][key][subIndex] = newValue;
    } else {
      updatedFields[index][key] = newValue;
    }
    setDocumentFields(updatedFields);
  };

  const handleAddField = (index, key) => {
    const updatedFields = [...documentFields];
    if (Array.isArray(updatedFields[index][key])) {
      updatedFields[index][key].push(""); // Add an empty string to the array
    } else {
      updatedFields[index][key] = [updatedFields[index][key], ""]; // Convert to array if not already
    }
    setDocumentFields(updatedFields);
  };

  const handleRemoveField = (index, key, subIndex) => {
    const updatedFields = [...documentFields];
    if (Array.isArray(updatedFields[index][key])) {
      updatedFields[index][key] = updatedFields[index][key].filter(
        (_, i) => i !== subIndex
      );
      setDocumentFields(updatedFields);
    }
  };

  return (
    <div className="document-Management">
      <Header ual={ual} />
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <div className="document-management-content">
          <h2>Document Fields</h2>
          {documentFields.length > 0 && (
            <>
              {documentFields.map((field, index) => (
                <div key={index} className="document-management-details">
                  {Object.entries(field).map(([key, value], subIndex) => {
                    if (["id", "createdAt", "updatedAt"].includes(key)) {
                      return null;
                    }
                    const formattedKey = formatFieldName(key);
                    return (
                      <div
                        key={subIndex}
                        className="document-management-fields">
                        <div className="strong">{formattedKey}:</div>
                        {isEditMode ? (
                          Array.isArray(value) ? (
                            <>
                              {value.map((val, i) => (
                                <div key={i} className="input-with-remove">
                                  <input
                                    type="text"
                                    value={val}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        key,
                                        e.target.value,
                                        i
                                      )
                                    }
                                  />
                                  <button
                                    onClick={() =>
                                      handleRemoveField(index, key, i)
                                    }
                                    className="remove-btn">
                                    X
                                  </button>
                                </div>
                              ))}
                              <div className="add-btn-div">
                                <button
                                  onClick={() => handleAddField(index, key)}
                                  className="add-btn">
                                  Add
                                </button>
                              </div>
                            </>
                          ) : (
                            <input
                              type="text"
                              value={value.toString()}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  key,
                                  e.target.value,
                                  subIndex
                                )
                              }
                            />
                          )
                        ) : (
                          <div className="document-values">
                            {Array.isArray(value)
                              ? value.join(", ")
                              : value.toString()}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ))}
            </>
          )}
          {ual?.activeUser && (
            <div className="button-div">
              <button className="edit-btn" onClick={handleEditMode}>
                {isEditMode ? "Save Changes" : "Edit"}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withUAL(DocumentManagement);
