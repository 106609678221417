import React from "react";
import "./FarmManagement.css";
import { withUAL } from "ual-reactjs-renderer";
import Header from "../../../components/header/Header";

const FarmManagement = ({ ual }) => {
  console.log("UAL in Farm-Manag", ual);
  return (
    <div className="farm-Management">
      <Header ual={ual} />
      <div className="farm-management-content">This is farm Management</div>
    </div>
  );
};

export default withUAL(FarmManagement);
