import React, { useState, useEffect } from "react";
import "./Crop.css";
import { withUAL } from "ual-reactjs-renderer";
import { FaPlus } from "react-icons/fa";
import Header from "../header/Header";
import AddCropModal from "../modal/addCropModal/AddCropModal";
import BlockchainService from "../../services/BlockchainService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader/Loader";
import CropFilters from "../searchFilters/cropFilters/CropFilters";

const blockchainService = new BlockchainService();

const Crop = ({ ual }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentCrop, setCurrentCrop] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [modalMode, setModalMode] = useState("add");
  const [crops, setCrops] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredCrops, setFilteredCrops] = useState([]);
  const [filtersActive, setFiltersActive] = useState(false);
  const [totalPages, setTotalPages] = useState(1); // Add state for total pages

  const openModal = (crop = null, index = null, mode = "add") => {
    setModalOpen(true);
    setCurrentCrop(crop);
    setCurrentIndex(index);
    setModalMode(mode);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentCrop(null);
    setCurrentIndex(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://api.growingprosperity.farm/api/v1/user/log/crop?page=1",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setIsNextPage(data.nextPage);
        setCrops(data.data);
        setTotalPages(Math.ceil(data.totalCrops / 10)); // Calculate total pages
      } catch (error) {
        console.error("Error fetching crop data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCropSubmit = async (submittedCropData, index) => {
    setIsLoading(true);
    const formatDateWithSlashes = (dateStr) => {
      if (!dateStr) return "";
      const [year, month, day] = dateStr.split("-");
      return `${year}/${month}/${day}`;
    };

    let formattedSubmissionData = {
      ...submittedCropData,
      plantingDate: formatDateWithSlashes(submittedCropData.plantingDate),
      harvestDate: formatDateWithSlashes(submittedCropData.harvestDate),
    };

    if (modalMode === "edit") {
      const { id, userId, createdAt, updatedAt, ...rest } =
        formattedSubmissionData;
      formattedSubmissionData = rest;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, unable to submit document");
      setIsLoading(false);
      return;
    }

    const apiUrl =
      modalMode === "edit" && currentCrop?.id
        ? `https://api.growingprosperity.farm/api/v1/user/log/crop/${currentCrop.id}`
        : "https://api.growingprosperity.farm/api/v1/user/log/crop";

    const httpMethod = modalMode === "edit" ? "PUT" : "POST";

    try {
      const response = await fetch(apiUrl, {
        method: httpMethod,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify(formattedSubmissionData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();

      if (responseData.success) {
        console.log("responseData", responseData);
        const transactionData = responseData.txn;
        console.log("transactionData", transactionData);

        const result = await blockchainService.pushTransaction(
          transactionData,
          ual,
          "invest",
          {}
        );

        if (result.success) {
          toast.success("Transaction Successful");
        }

        if (modalMode === "edit") {
          const updatedCrops = crops.map((crop, idx) =>
            idx === index ? { ...crop, ...formattedSubmissionData } : crop
          );
          setCrops(updatedCrops);
        } else {
          setCrops((prevCrops) => [
            { ...responseData.data, ...formattedSubmissionData },
            ...prevCrops,
          ]);
        }
      } else {
        toast.error(`Error: ${responseData.message}`);
      }
    } catch (error) {
      console.error("Failed to submit crop:", error);
      toast.error("Failed to submit crop.");
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const paginate = async (pageNumber) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/crop?page=${pageNumber}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setIsNextPage(data.nextPage);
      setCurrentPage(pageNumber);
      setCrops(data.data);
    } catch (error) {
      console.error("Error fetching crop data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteCrop = (index) => {
    const updatedCrops = [...crops];
    updatedCrops.splice(index, 1);
    setCrops(updatedCrops);
  };

  const handleSearch = async (filters) => {
    setIsLoading(true);
    try {
      const queryString = Object.entries(filters)
        .filter(([_, value]) => value !== "")
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/crop?${queryString}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.data.length === 0) {
        toast.info("No data has been found for these filters.");
        setFilteredCrops([]);
        setFiltersActive(true); // still set to true to show no data message
      } else {
        setFilteredCrops(data.data);
        setFiltersActive(true);
      }
    } catch (error) {
      console.error("Error fetching filtered crop data:", error);
      toast.error("Failed to fetch filtered crop data.");
      setFilteredCrops([]);
      setFiltersActive(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetFilters = () => {
    setFilteredCrops([]);
    setFiltersActive(false);
  };

  const dataToShow = filtersActive ? filteredCrops : crops;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 5; // Adjust this value as needed
    let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
    let endPage = startPage + maxPageButtons - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxPageButtons + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`page-number${currentPage === i ? " active" : ""}`}
          onClick={() => paginate(i)}>
          {i}
        </button>
      );
    }

    return (
      <div className="pagination">
        {currentPage > 1 && (
          <button onClick={() => paginate(1)}>&laquo; First</button>
        )}
        {currentPage > 1 && (
          <button onClick={() => paginate(currentPage - 1)}>Previous</button>
        )}
        {pageNumbers}
        {currentPage < totalPages && (
          <button onClick={() => paginate(currentPage + 1)}>Next</button>
        )}
        {currentPage < totalPages && (
          <button onClick={() => paginate(totalPages)}>Last &raquo;</button>
        )}
      </div>
    );
  };

  return (
    <div className="crop">
      <Header ual={ual} />
      <div className="crop-content">
        <div className="search-box">
          <div className="search-box-content">
            <CropFilters
              onSearch={handleSearch}
              onReset={handleResetFilters}
              isOpen={isModalOpen}
            />
          </div>
        </div>
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : dataToShow.length === 0 ? (
          <div className="no-data-message">No records found.</div>
        ) : (
          <div className="table-container">
            <table className="crop-table">
              <thead>
                <tr>
                  <th>Record Type</th>
                  <th>Linked Record</th>
                  <th>Period</th>
                  <th>Farm Name</th>
                  <th>Field Name</th>
                  <th>Crop</th>
                  <th>Area Planted</th>
                  <th>Area Unit</th>
                  <th>Amount Harvested</th>
                  <th>Amount Unit</th>
                  <th>Memo</th>
                  <th>Crop Type</th>
                  <th>Expected Harvest</th>
                  <th>Area</th>
                  <th>Inputs</th>
                  <th>Acres Cleared</th>
                  <th>Acres Planted</th>
                </tr>
              </thead>
              <tbody>
                {dataToShow.map((crop, index) => (
                  <tr
                    key={index}
                    onClick={() => openModal(crop, index, "show")}>
                    <td>{crop.recordType}</td>
                    <td>{crop.linkedRecord}</td>
                    <td>{crop.period}</td>
                    <td>{crop.farmName}</td>
                    <td>{crop.fieldName}</td>
                    <td>{crop.crop}</td>
                    <td>{crop.areaPlanted}</td>
                    <td>{crop.areaUnit}</td>
                    <td>{crop.amountHarvested}</td>
                    <td>{crop.amountUnit}</td>
                    <td>{crop.memo}</td>
                    <td>{crop.type}</td>
                    <td>{crop.firstHarvest}</td>
                    <td>{crop.area}</td>
                    <td>
                      {crop.inputs &&
                      typeof crop.inputs === "object" &&
                      Object.keys(crop.inputs).length > 0
                        ? Object.entries(crop.inputs)
                            .filter(([key, value]) => key && value)
                            .map(([key, value]) => `${key}: ${value}`)
                            .join(", ")
                        : ""}
                    </td>
                    <td>{crop.acresCleared}</td>
                    <td>{crop.acresPlanted}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="crop-footer">
        {renderPageNumbers()}
        {ual?.activeUser && (
          <button
            className="add-crop-btn"
            onClick={() => openModal(null, null, "add")}>
            <FaPlus /> Add Crop
          </button>
        )}
      </div>

      <AddCropModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleCropSubmit}
        crop={currentCrop}
        mode={modalMode}
        setMode={setModalMode}
        currentIndex={currentIndex}
        onDelete={deleteCrop}
        ual={ual}
      />
    </div>
  );
};

export default withUAL(Crop);
