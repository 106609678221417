import React from "react";
import Header from "../header/Header";
import help from "../../images/help.svg";

const Help = () => {
  return (
    <>
      <Header />
      <div className="setting">
        <img src={help} alt="" className="setting_pic" />
        <h3 className="setting_text">Help Coming Soon.....</h3>
      </div>
    </>
  );
};

export default Help;
