import React from "react";
import "./GeaData.css";

const GeaData = () => {
  return (
    <div className="main-container">
      <h2 style={{ textAlign: "center" }}>
        Live Project: Atakwa Farm, Central Region, Ghana
      </h2>
      <h3 style={{ textAlign: "center" }}>100 acres</h3>
      <h4 style={{ textAlign: "center" }}>Current Status</h4>
      <div className="container">
        <table>
          <thead>
            <tr>
              <th rowSpan="2">Crops</th>
              <th rowSpan="2">Type</th>
              <th colSpan="2">Acres</th>
              <th rowSpan="2">Completed</th>
              <th rowSpan="2">Expected harvest</th>
            </tr>
            <tr>
              <th>Planned</th>
              <th>Planted</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Maize (Intercropped)</td>
              <td>Seasonal</td>
              <td>40</td>
              <td>40</td>
              <td className="completed-100">100%</td>
              <td>Sep-24</td>
            </tr>
            <tr>
              <td>Cassava (Intercropped)</td>
              <td>Seasonal</td>
              <td>50</td>
              <td>50</td>
              <td className="completed-100">100%</td>
              <td>Apr-25</td>
            </tr>
            <tr>
              <td>Palm (Intercropped)</td>
              <td>Perennial</td>
              <td>100</td>
              <td>70</td>
              <td className="completed-70">70%</td>
              <td>Mid 2027</td>
            </tr>
            <tr>
              <td>Coconut (Intercropped)</td>
              <td>Perennial</td>
              <td>500 saplings</td>
              <td>500 saplings</td>
              <td className="completed-100">100%</td>
              <td>Mid 2028</td>
            </tr>
          </tbody>
        </table>
        <h4 style={{ textAlign: "center" }}>Farm inputs</h4>
        <table>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold", textAlign: "left" }}>
                a) Labor (avg daily)
              </td>
              <td>80 People</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>M/F</td>
              <td>25/55</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold", textAlign: "left" }}>
                b) Fertilizers
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>Ammonium Sulphate</td>
              <td>550 kg</td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>N P K</td>
              <td>1100 kg</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GeaData;
