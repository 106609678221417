import React, { useEffect, useState } from "react";
import "./Income.css";
import { withUAL } from "ual-reactjs-renderer";
import { FaPlus } from "react-icons/fa";
import Header from "../header/Header";
import AddIncomeModal from "../modal/addIncomeModal/AddIncomeModal";
import BlockchainService from "../../services/BlockchainService";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
import IncomeFilters from "../searchFilters/incomeFilters/IncomeFilters";
import { FaInfoCircle } from "react-icons/fa";

const blockchainService = new BlockchainService();

const Income = ({ ual }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentIncome, setCurrentIncome] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [modalMode, setModalMode] = useState("add"); // Default mode is 'add'
  const [incomes, setIncomes] = useState([]); // Initial income records
  const [currentPage, setCurrentPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredIncomes, setFilteredIncomes] = useState([]);
  const [filtersActive, setFiltersActive] = useState(false);

  const openModal = (income = null, index = null, mode = "add") => {
    setModalOpen(true);
    setCurrentIncome(income);
    setCurrentIndex(index);
    setModalMode(mode); // Mode can be 'add', 'edit', or 'show'
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentIncome(null);
    setCurrentIndex(null);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.growingprosperity.farmapi/v1/user/log/income"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // console.log("Income Data:", data);
        setIsNextPage(data.nextPage);
        setIncomes(data.data);
      } catch (error) {
        console.error("Error fetching income data:", error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    };

    fetchData();
  }, []);

  const handleIncomeSubmit = async (submittedIncomeData, index) => {
    setIsLoading(true);

    // Exclude fields for "edit" mode before submission
    if (modalMode === "edit") {
      const { id, userId, createdAt, updatedAt, ...rest } = submittedIncomeData;
      submittedIncomeData = rest;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, unable to submit income data");
      setIsLoading(false);
      localStorage.removeItem("token");
      return;
    }

    const apiUrl =
      modalMode === "edit" && currentIncome?.id
        ? `https://api.growingprosperity.farm/api/v1/user/log/income/${currentIncome.id}`
        : "https://api.growingprosperity.farm/api/v1/user/log/income";

    const httpMethod = modalMode === "edit" ? "PUT" : "POST";

    try {
      const response = await fetch(apiUrl, {
        method: httpMethod,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify(submittedIncomeData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.success) {
        const transactionData = responseData.tnx; // This is a placeholder and should match your actual API response

        // Submit the transaction to the blockchain
        const result = await blockchainService.pushTransaction(
          transactionData,
          ual,
          "invest", // The actual action name here depends on your smart contract
          {} // Any additional options required for the transaction
        );

        if (result.success) {
          toast.success(
            modalMode === "edit"
              ? "Income updated successfully"
              : "Income added successfully"
          );
        }

        // Update the incomes array or state as needed here
        if (modalMode === "edit") {
          setIncomes(
            incomes.map((income, idx) =>
              idx === index ? submittedIncomeData : income
            )
          );
        } else {
          // Add the new income at the start of the array
          setIncomes([submittedIncomeData, ...incomes]);
        }
      } else {
        toast.error(`Error: ${responseData.message}`);
      }
    } catch (error) {
      console.error("Failed to submit income data:", error);
      toast.error("Failed to submit income data.");
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const paginate = async (pageNumber) => {
    setIsLoading(true); // Set loading to true when paginating
    try {
      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/income?page=${pageNumber}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Get response of crops", data);
      setIsNextPage(data.nextPage);
      setCurrentPage(pageNumber);
      setIncomes(data.data);
    } catch (error) {
      console.error("Error fetching crop data:", error);
    } finally {
      setIsLoading(false); // Set loading to false after paginating
    }
  };

  const deleteIncome = (index) => {
    const updatedIncomes = incomes.filter((_, i) => i !== index);
    setIncomes(updatedIncomes);
  };

  // New function to handle search with filters
  const handleSearch = async (filters) => {
    setIsLoading(true);
    try {
      // Constructing query string from filters
      const queryString = Object.entries(filters)
        .filter(([_, value]) => value) // Remove entries with empty values
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/income?${queryString}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.data.length === 0) {
        toast.info("No data has been found for these filters.");
        setFiltersActive(true);
        setFilteredIncomes([]);
      } else {
        setFilteredIncomes(data.data);
        setFiltersActive(true);
      }
    } catch (error) {
      console.error("Error fetching filtered income data:", error);
      toast.error("An error occurred while fetching the filtered data.");
      setFiltersActive(false);
    } finally {
      setIsLoading(false);
    }
  };

  //  function to handle reset action
  const handleResetFilters = () => {
    setFilteredIncomes([]);
    setFiltersActive(false);
  };

  console.log("Filtered incomes", filteredIncomes);

  return (
    <div className="income">
      <Header ual={ual} />
      <div className="income-content">
        <div className="search-box">
          <div className="search-box-content">
            <IncomeFilters
              onSearch={handleSearch}
              onReset={handleResetFilters}
            />
          </div>
        </div>
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : filtersActive ? (
          filteredIncomes.length === 0 ? (
            <div className="no-data-message">No records found.</div>
          ) : (
            <div className="table-container">
              <table className="income-table">
                <thead>
                  <tr>
                    <th>
                      Record Type <FaInfoCircle />
                    </th>
                    <th>
                      Linked Record <FaInfoCircle />
                    </th>
                    <th>
                      Period <FaInfoCircle />
                    </th>
                    <th>
                      Type <FaInfoCircle />
                    </th>
                    <th>
                      Source <FaInfoCircle />
                    </th>
                    <th>
                      Ledger <FaInfoCircle />
                    </th>
                    <th>
                      Record Reference <FaInfoCircle />
                    </th>
                    <th>
                      Amount <FaInfoCircle />
                    </th>
                    <th>
                      Currency <FaInfoCircle />
                    </th>
                    <th>
                      Memo <FaInfoCircle />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredIncomes.map((income, index) => (
                    <tr
                      key={index}
                      onClick={() => openModal(income, index, "show")}>
                      <td>{income.recordType}</td>
                      <td>{income.linkedRecord}</td>
                      <td>{income.period}</td>
                      <td>{income.type}</td>
                      <td>{income.source}</td>
                      <td>{income.ledger}</td>
                      <td>{income.recordReference}</td>
                      <td>{income.amount}</td>
                      <td>{income.currency}</td>
                      <td>{income.memo}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        ) : incomes.length === 0 ? (
          <div className="no-data-message">No records found.</div>
        ) : (
          <div className="table-container">
            <table className="income-table">
              <thead>
                <tr>
                  <th>
                    Record Type <FaInfoCircle />
                  </th>
                  <th>
                    Linked Record <FaInfoCircle />
                  </th>
                  <th>
                    Period <FaInfoCircle />
                  </th>
                  <th>
                    Type <FaInfoCircle />
                  </th>
                  <th>
                    Source <FaInfoCircle />
                  </th>
                  <th>
                    Ledger <FaInfoCircle />
                  </th>
                  <th>
                    Record Reference <FaInfoCircle />
                  </th>
                  <th>
                    Amount <FaInfoCircle />
                  </th>
                  <th>
                    Currency <FaInfoCircle />
                  </th>
                  <th>
                    Memo <FaInfoCircle />
                  </th>
                </tr>
              </thead>
              <tbody>
                {incomes.map((income, index) => (
                  <tr
                    key={index}
                    onClick={() => openModal(income, index, "show")}>
                    <td>{income.recordType}</td>
                    <td>{income.linkedRecord}</td>
                    <td>{income.period}</td>
                    <td>{income.type}</td>
                    <td>{income.source}</td>
                    <td>{income.ledger}</td>
                    <td>{income.recordReference}</td>
                    <td>{income.amount}</td>
                    <td>{income.currency}</td>
                    <td>{income.memo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="income-footer">
        <div className="pagination">
          <button
            className={`prev-btn${currentPage === 1 ? " disabled" : ""}`}
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}>
            Previous
          </button>
          <button
            className={`next-btn${!isNextPage ? " disabled" : ""}`}
            onClick={() => paginate(currentPage + 1)}
            disabled={!isNextPage}>
            Next
          </button>
        </div>
        {ual?.activeUser && (
          <button
            className="add-income-btn"
            onClick={() => openModal(null, null, "add")}>
            <FaPlus /> Add Income
          </button>
        )}
      </div>
      <AddIncomeModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleIncomeSubmit}
        income={currentIncome}
        mode={modalMode}
        setMode={setModalMode}
        currentIndex={currentIndex}
        onDelete={deleteIncome}
        ual={ual}
      />
    </div>
  );
};

export default withUAL(Income);
