import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import "./AddExpenseModal.css";
import crop4 from "../../../images/crop4.jpeg";
import crop2new from "../../../images/crop2new.jpg";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";

const AddExpenseModal = ({
  isOpen,
  onClose,
  onSubmit,
  expense,
  mode,
  setMode, // Added for consistency
  currentIndex, // Added for consistency
  onDelete,
  ual,
}) => {
  const defaultExpenseData = {
    recordType: "",
    linkedRecord: "",
    period: "",
    type: "",
    class: "",
    recipient: "",
    ledger: "",
    recordReference: "",
    amount: "",
    currency: "",
    memo: "",
  };

  const [currentMode, setCurrentMode] = useState(mode);
  const [isLoading, setIsLoading] = useState(false);
  const [editableExpenseData, setEditableExpenseData] =
    useState(defaultExpenseData);
  const [selectData, setSelectData] = useState([]);

  useEffect(() => {
    const fetchCropFields = async () => {
      if (isOpen) {
        setIsLoading(true);
        try {
          const response = await fetch(
            "https://api.growingprosperity.farm/api/v1/expense/fields"
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setSelectData(data.data);
          console.log(data.data);
        } catch (error) {
          console.error("Could not fetch crop fields data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchCropFields();
  }, [isOpen]);

  console.log("Select Data", selectData);

  useEffect(() => {
    if (["edit", "show"].includes(mode) && expense) {
      setEditableExpenseData(expense);
    } else if (mode === "add") {
      setEditableExpenseData(defaultExpenseData);
    }
    setCurrentMode(mode);
  }, [expense, mode]);

  const handleEdit = () => {
    setMode("edit"); // Update the mode to 'edit'
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, unable to submit document");
      setIsLoading(false);
      return;
    }
    try {
      const id = expense.id;

      const response = await fetch(
        `https://api.growingprosperity.farm/api/v1/user/log/expense/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.success) {
        toast.success("Expense deleted successfully");
        onDelete(currentIndex); // This line notifies the parent component to update its state
      } else {
        toast.error(`Error: ${responseData.message}`);
      }
    } catch (error) {
      console.error("Failed to delete expense:", error);
      toast.error("Failed to delete expense.");
    } finally {
      setIsLoading(false);
      onClose(); // Close the modal whether deletion was successful or failed
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableExpenseData((prevData) => ({ ...prevData, [name]: value }));
  };
  const splitNameOnUppercase = (name) => {
    const index = Array.from(name).findIndex(
      (char) => char === char.toUpperCase()
    );
    console.log("INDEX", index);
    if (index !== -1) {
      const part1 = name.charAt(0).toUpperCase() + name.slice(1, index) + " ";

      const part2 = name.slice(index);
      console.log("part name", `${part1} ${part2}`);
      return `${part1} ${part2}`;
    } else {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  };

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();

    const submissionData = { ...editableExpenseData };

    // If 'recordType' is 'New', ensure 'linkedRecord' is an empty string
    if (submissionData.recordType === "New") {
      submissionData.linkedRecord = " ";
    }

    if (["edit", "add"].includes(currentMode)) {
      console.log("Submitting", submissionData, currentIndex); // Logging the submission data for verification
      onSubmit(submissionData, currentIndex); // Use the modified data for submission
    }

    onClose(); // Close the modal
  };

  const renderFormContent = () => {
    const excludeFieldsInShowMode = ["id", "userId"];
    const excludeFieldsInEditMode = ["id", "userId", "createdAt", "updatedAt"];

    // Assuming selectData structure is appropriate for your expense fields,
    // and it includes 'recordType' with its options.
    const dynamicOptions = selectData.length > 0 ? selectData[0] : {};

    return Object.keys(editableExpenseData).map((key) => {
      if (currentMode === "show" && excludeFieldsInShowMode.includes(key)) {
        return null;
      }

      if (currentMode === "edit" && excludeFieldsInEditMode.includes(key)) {
        return null;
      }

      // Exclude "linkedRecord" if "recordType" is "New"
      if (
        key === "linkedRecord" &&
        editableExpenseData["recordType"] === "New"
      ) {
        return null; // Skip rendering "linkedRecord" when "New" is selected for "recordType"
      }

      // In "show" mode, display values as text instead of input fields
      if (currentMode === "show") {
        return (
          <div className="input-box" key={key}>
            <label className="form-label">{splitNameOnUppercase(key)}:</label>
            <p className="form-text">{editableExpenseData[key]}</p>
          </div>
        );
      }

      // Check if the current field is 'recordType' and we are in 'add' mode
      if (key === "recordType" && currentMode === "add") {
        const recordTypeOptions = dynamicOptions["recordType"] || [
          "New",
          "Edit",
          "Update",
        ]; // Fallback to default options

        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <select
              id={key}
              name={key}
              className="select form-select"
              value={editableExpenseData[key] || ""}
              onChange={handleChange}
              required>
              <option value="">Select an option</option>
              {recordTypeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      } else if (currentMode === "add" && dynamicOptions[key]) {
        // Handling for other fields with dynamic options in "add" mode
        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <select
              id={key}
              name={key}
              className="select form-select"
              value={editableExpenseData[key] || ""}
              onChange={handleChange}
              required>
              <option value="">Select an option</option>
              {dynamicOptions[key] &&
                dynamicOptions[key].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
            </select>
          </div>
        );
      } else {
        // Default input handling for text and other types, including 'period' as a date field
        return (
          <div className="input-box" key={key}>
            <label htmlFor={key} className="form-label">
              {splitNameOnUppercase(key)}
            </label>
            <input
              type={
                key === "amount" ? "number" : key === "period" ? "date" : "text"
              }
              id={key}
              name={key}
              className="input form-input"
              value={editableExpenseData[key] || ""}
              onChange={handleChange}
              required={currentMode !== "show"}
            />
          </div>
        );
      }
    });
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <div className="modal-image">
          <img className="crop-img" src={crop4} alt="" />
          <div class="overlay">
            <img src={crop2new} alt="" className="overlay-image" />
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-heading">
              {currentMode === "edit"
                ? "Edit Expense"
                : currentMode === "add"
                ? "Add Expense"
                : "Expense Details"}
            </h2>
            <FaTimes className="modal-close" onClick={onClose} />
          </div>
          <form onSubmit={handleSubmit} className="modal-form">
            {renderFormContent()}
            {currentMode === "show" && ual?.activeUser && (
              <div className="show-btns">
                <button
                  type="button"
                  className="button-common edit-btn"
                  onClick={handleEdit}>
                  Edit
                </button>
                <button
                  type="button"
                  className="button-common delete-btn"
                  onClick={handleDelete}>
                  Delete
                </button>
              </div>
            )}
            {currentMode !== "show" && (
              <button type="submit" className="button-common submit-btn">
                {currentMode === "edit" ? "Update" : "Submit"}
              </button>
            )}
            {isLoading && <Loader isLoading={isLoading} />}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddExpenseModal;
